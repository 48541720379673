import gql from 'graphql-tag';

const DriverAssignments = gql(/* GraphQL */ `
  query DriverAssignments($eventAtRange: TimeRangeInput) {
    driverAssignments(eventAtRange: $eventAtRange) {
      id
      deliveryStatus
      delivery {
        id
        eventAtWithPadding
        orderNumber
        pickupAddress {
          id
          timeZoneIdentifier
        }
        dropoffAddress {
          id
          locationName
          street1
          street2
          city
          state
          zip
        }
      }
    }
  }
`);

export default DriverAssignments;
