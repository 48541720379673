import {EzLayout, EzStatus, EzTextStyle} from '@ezcater/recipe';
import {formatOrderNumber} from 'util/StringUtils';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import type {DriverAssignmentsQuery} from 'services/graphql/types';
import {DmDeliveryEventTypes} from 'services/graphql/types';
import dateToTimeFormatter from 'services/dateToTimeFormatter';
import {
  CalendarCard,
  CardLink,
  Month,
  DayOfMonth,
  OrderNumber,
  RightSide,
} from './DeliveryCard.styles';

type DriverAssignment = DriverAssignmentsQuery['driverAssignments'][number];

interface DeliveryCardProps {
  assignment: DriverAssignment;
}

const success = new Set([
  DmDeliveryEventTypes.DriverDroppedOff,
  DmDeliveryEventTypes.Expired,
  DmDeliveryEventTypes.DriverProvidedFeedback,
]);
const neutral = new Set([DmDeliveryEventTypes.DriverAssigned]);

export function pillColor(status: DriverAssignment['deliveryStatus']) {
  if (success.has(status)) {
    return 'success' as const;
  }
  if (neutral.has(status)) {
    return 'neutral' as const;
  }
  return 'informational' as const;
}

const DeliveryCard = ({assignment}: DeliveryCardProps) => {
  const {t} = useTranslation();
  const {delivery} = assignment;
  const date = new Date(delivery.eventAtWithPadding);
  const history = useHistory();

  const statusesMap: {[key in DmDeliveryEventTypes]?: string} = {
    [DmDeliveryEventTypes.DriverAssigned]: t('driverDeliveryStatuses.driverAssigned'),
    [DmDeliveryEventTypes.DriverArrivedAtPickup]: t('driverDeliveryStatuses.atPickup'),
    [DmDeliveryEventTypes.DriverPickedUp]: t('driverDeliveryStatuses.pickedUp'),
    [DmDeliveryEventTypes.DriverArrivedAtDropoff]: t('driverDeliveryStatuses.atDropoff'),
    [DmDeliveryEventTypes.DriverDroppedOff]: t('driverDeliveryStatuses.droppedOff'),
    [DmDeliveryEventTypes.Expired]: t('driverDeliveryStatuses.expired'),
    [DmDeliveryEventTypes.DriverProvidedFeedback]: t('driverDeliveryStatuses.droppedOff'),
  };
  const statusText =
    assignment.deliveryStatus in statusesMap ? statusesMap[assignment.deliveryStatus] : '';
  const statusPillColor = pillColor(assignment.deliveryStatus);

  const cardClick = () => {
    history.push(`/assignment/${assignment.id}`);
  };

  return (
    <CardLink onClick={cardClick}>
      <EzLayout layout="split">
        <EzLayout>
          <EzLayout layout="stack">
            <CalendarCard>
              <Month>{format(date, 'E').toUpperCase()}</Month>
              <DayOfMonth>
                {format(date, 'LLL').toUpperCase()} {date.getDate()}
              </DayOfMonth>

              <EzTextStyle align="center">
                {dateToTimeFormatter(date, delivery.pickupAddress.timeZoneIdentifier)}
              </EzTextStyle>
            </CalendarCard>
          </EzLayout>
          <EzLayout layout="stack">
            <div>
              <OrderNumber>{formatOrderNumber(delivery.orderNumber)}</OrderNumber>
            </div>
            <div>
              <p>{delivery.dropoffAddress.street1}</p>
              {delivery.dropoffAddress.street2 && <p>{delivery.dropoffAddress.street2}</p>}
              <p>
                {delivery.dropoffAddress.city},&nbsp;
                {delivery.dropoffAddress.state}&nbsp;
                {delivery.dropoffAddress.zip}
              </p>
            </div>
            {statusText && (
              <div data-testid="delivery-status-text">
                <EzStatus text={statusText} use={statusPillColor} data-testid={statusPillColor} />
              </div>
            )}
          </EzLayout>
        </EzLayout>
        <RightSide />
      </EzLayout>
    </CardLink>
  );
};

export default DeliveryCard;
