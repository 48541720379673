import {useState} from 'react';
import {useMutation} from 'services/apollo';
import {EzHeading, EzLink, EzLayout, EzButton} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import {REQUEST_SIGN_IN_LINK_PATH} from 'paths';
import {RemoveAssignments} from 'services/graphql';
import {SelfAssignErrorTypes} from 'services/graphql/types';
import type {
  RemoveAssignmentsMutation,
  RemoveAssignmentsMutationVariables,
  SelfAssignDriverMutationVariables,
  SelfAssignSource,
} from 'services/graphql/types';
import {useCurrentAccount} from '../CurrentAccount';

const getHasDynamicErrorMessage = (message?: string): message is SelfAssignErrorTypes => {
  return Object.values(SelfAssignErrorTypes).some(v => v === message);
};

interface SelfAssignErrorProps {
  message?: SelfAssignErrorTypes | string;
  deliveryId?: string;
  source?: SelfAssignSource | `${SelfAssignSource}`;
  showOverrideOptions?: boolean;
  handleSelfAssign?: ({variables}: {variables: SelfAssignDriverMutationVariables}) => void;
}

const SelfAssignError = ({
  message,
  showOverrideOptions,
  deliveryId,
  source,
  handleSelfAssign,
}: SelfAssignErrorProps) => {
  const {t, i18n} = useTranslation();
  const [showButtons, setShowButtons] = useState(showOverrideOptions);
  const [hasErrors, setHasErrors] = useState(false);
  const sorry = t('selfAssignError.sorry');

  // If auth'ed, no sign in link
  const explanationAuthenticated = t('selfAssignError.explanation.authenticated');

  // If not auth'ed, show sign in link
  const explanationPart1 = t('selfAssignError.explanation.part1');
  const signIn = t('selfAssignError.explanation.signIn');
  const explanationPart2 = t('selfAssignError.explanation.part2');

  const hasDynamicErrorMessage = getHasDynamicErrorMessage(message);

  const [removeAssignmentsQuery] = useMutation<
    RemoveAssignmentsMutation,
    RemoveAssignmentsMutationVariables
  >(RemoveAssignments, {
    onError: () => {
      setHasErrors(true);
    },
  });

  const {id} = useCurrentAccount();
  const isLoggedIn = !!id;

  const overrideExistingAssignment = async () => {
    if (deliveryId && source) {
      const {data} = await removeAssignmentsQuery({variables: {input: {deliveryId}}});
      if (data?.removeAssignments?.errors) {
        setHasErrors(true);
      }

      if (data?.removeAssignments?.deliveryId && handleSelfAssign) {
        handleSelfAssign({
          variables: {
            input: {
              deliveryId: data.removeAssignments.deliveryId,
              source,
            },
          },
        });
      }
    }
  };

  return (
    <>
      <EzHeading size="2">{sorry}</EzHeading>
      {hasDynamicErrorMessage && i18n.exists(`selfAssignError.explanation.${message}`) && (
        <EzHeading size="4">{t(`selfAssignError.explanation.${message}`)}</EzHeading>
      )}
      {(!hasDynamicErrorMessage || hasErrors) && (
        <EzHeading size="4">
          {isLoggedIn ? (
            explanationAuthenticated
          ) : (
            <>
              {explanationPart1}
              &nbsp;
              <EzLink href={REQUEST_SIGN_IN_LINK_PATH}>{signIn}</EzLink>
              &nbsp;
              {explanationPart2}
            </>
          )}
        </EzHeading>
      )}
      {showButtons && (
        <EzLayout layout="stack" alignX="center">
          <EzButton use="primary" onClick={overrideExistingAssignment}>
            Yes, assign myself
          </EzButton>
          <EzButton use="secondary" onClick={() => setShowButtons(false)}>
            No, keep driver assigned
          </EzButton>
        </EzLayout>
      )}
    </>
  );
};

export default SelfAssignError;
