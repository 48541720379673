import gql from 'graphql-tag';

const DriverAssignment = gql(/* GraphQL */ `
  query DriverAssignment($id: ID!) {
    driverAssignment: dmDriverAssignment(id: $id) {
      id
      deliveryStatus
      delivery {
        id
        orderNumber
        eventAtWithPadding
        distanceInMiles
        durationInMinutes
        driverAskFor
        pickupAddress {
          id
          instructions
          locationName
          street1
          street2
          city
          state
          zip
        }
        dropoffAddress {
          id
          locationName
          street1
          street2
          city
          state
          zip
          phone
          instructions
          timeZoneIdentifier
        }
        order {
          orderItems {
            name
            quantity
          }
        }
      }
    }
  }
`);

export default DriverAssignment;
