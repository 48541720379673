const trackingMetadata = () => {
  const windowEvents =
    typeof window === 'undefined'
      ? {}
      : {
          url: window.location.href,
          browser_height: window.innerHeight || 0,
          browser_width: window.innerWidth || 0,
          pixel_ratio: window.devicePixelRatio || 0,
        };

  return windowEvents;
};

export default trackingMetadata;
