export default {
  completedDeliveries: {
    title: 'Completed Deliveries - ezCater Delivery',
    header: 'Completed Deliveries',
    tableTitle: 'Completed',
  },
  myDeliveries: {
    title: 'My Deliveries - ezCater Delivery',
    header: 'My Deliveries',
    tableTitleToday: 'Today',
    tableTitleUpcoming: 'Upcoming',
    gettingStarted: {
      title: 'Getting Started',
      message: 'Watch a quick 1 minute video tutorial on how the driver tracking portal works',
      link: 'Watch the video',
    },
  },
  driverDeliveryStatuses: {
    driverAssigned: 'Delivery Scheduled',
    atPickup: 'Preparing Order for Delivery',
    pickedUp: 'En Route to Customer',
    atDropoff: 'Arrived at Dropoff',
    droppedOff: 'Complete',
    expired: 'Expired',
  },
  requestInvitation: {
    title: 'Request Invitation - ezCater Delivery',
    header: 'Profile Activation',
    details:
      'Create a driver profile so you can easily track and manage the deliveries your restaurant assigns to you.',
    success:
      "If you have an existing driver profile with us you will receive an SMS shortly.  If you don't receive the SMS, contact your catering manager.",
  },
  selfAssign: {
    title: 'Self Assign - ezCater Delivery',
    header: 'Delivering an order?',
    details: 'Create a driver profile so you can easily track and manage your deliveries.',
    success:
      'You will receive an SMS shortly with instructions to create your driver profile. If you don’t receive the SMS, contact your catering manager.',
  },
  selfAssignError: {
    sorry: "We're sorry...",
    explanation: {
      authenticated:
        'We are unable to assign you that delivery. If you believe that this is a mistake, please contact your catering manager or ezCater support.',
      part1: 'We are unable to assign you that delivery. If you have already been assigned, please',
      signIn: 'sign in',
      part2:
        'to view the order. Otherwise, please contact your catering manager or ezCater support.',
      assigned:
        'We were unable to assign you to this delivery because there is already another driver assigned.',
      finalized:
        'We were unable to assign you to this delivery because this order is no longer active for delivery. Please contact your catering manager or ezCater support for additional questions.',
      takeout:
        'We were unable to assign you to this delivery because this order is no longer active for delivery. Please contact your catering manager or ezCater support for additional questions.',
      ezdispatch:
        'We were unable to assign you to this delivery because ezDispatch is scheduled to deliver this order. Please contact your catering manager or ezCater support for additional questions.',
      relish:
        'We were unable to assign you to this delivery because this is a Relish order. Please contact your catering manager or relish support. 617-826-1763.',
    },
  },
  expiredMagicLink: {
    title: 'Expired Link - ezCater Delivery',
    header: 'Expired Link',
    subheader:
      'To view your deliveries, add your phone number below and we will SMS you a secure link.',
    successHeader: 'Look out for a text from us',
    successSubheader:
      "You should receive a text message shortly with a link to the order details. If you don't receive one, please contact your catering manager.",
    button: 'Text me the delivery link',
    phoneNumber: 'Verify Phone Number',
    phoneNumberPlaceholder: 'Enter your phone number',
    error: 'There was an error requesting a new magic link.  Please try again.',
    disclaimer:
      'We may send you informational and transactional text messages such as delivery information.',
    terms: 'Terms apply.',
    details: 'Look out for a text from us',
  },
  requestSignInLink: {
    title: 'Sign In - ezCater Delivery',
    header: 'Delivering an order?',
    subheader: 'Enter your phone number and we will text you with a link to the delivery details.',
    button: 'Text me the delivery link',
    phoneNumber: 'Verify Phone Number',
    phoneNumberPlaceholder: 'Enter your phone number',
    error: 'There was an error requesting a new magic link.  Please try again.',
    disclaimer:
      'We may send you informational and transactional text messages such as delivery information.',
    terms: 'Terms apply.',
    details: 'Look out for a text from us',
  },
  requestSignInLinkSuccess: {
    suggestionsHeader: "Didn't get our text?",
    suggestionsSubheader: 'This could have happened for one of the following reasons:',
    suggestionOne:
      'Your number is not assigned as a driver for any restaurant. The restaurant manager will need to add you as a driver in ezManage first.',
    suggestionTwo:
      'You may have opted out of our messages previously. In that case, try sending START to',
    titleHeader: 'Look out for the text, it should arrive shortly!',
    titleSubheader: 'You should receive a text message shortly with a link to the order details.',
  },
  feedbackForm: {
    labels: {
      kitchen: 'Kitchen',
      reception: 'Reception',
      other: 'Other',
      dropoffLocation: 'Where was the food delivered?',
      otherDropoffLocation: 'Other',
      message: 'Notes about this delivery.',
      submit: 'Send feedback',
    },
    placeholders: {
      otherDropoffLocation: 'Outside building, still with you, etc.',
      message: 'Parking, Building Access, etc.',
    },
    genericErrorMessage: 'There was an error processing your request.',
  },
  footer: {
    questions: 'Questions?',
    copyright: '© ezCater, Inc.',
    privacyPolicy: 'Privacy Policy',
    caNoticeOfCollection: 'California Notice of Collection',
  },
  help: {
    title: 'Help - ezCater Delivery',
    header: 'Need Help?',
    urgent1: 'For urgent help, please call us at',
    urgent2: 'to speak with a member of our customer service team.',
    order: 'Question about this order? Reference order number',
    otherQuestions: 'For all other question please email us at',
    video: {
      header: 'New to ezCater?',
      message: 'Watch the',
      link: 'quick guide video',
    },
    privacy: {
      header: 'Privacy Policy',
      privacyPolicy: 'Privacy Policy',
      caNoticeOfCollection: 'California Notice of Collection',
    },
    accountDeletion: {
      header: 'Account',
      info: 'You can delete your account',
      link: 'here',
    },
  },
  accountDeletionModal: {
    title: 'Are you sure you want to delete your account?',
    disclaimer:
      'All of your previous delivery information and records will be unrecoverable after submitting this request. You will no longer be able to log into this user account. Catering partners and customers may have access to delivery data shared prior to your account deletion request.',
    buttons: {
      dismiss: 'Nevermind',
      submit: 'Delete Account',
    },
    success: 'We successfully deleted your account.',
    failure: 'Oh crumbs! Something went wrong. Please try again.',
  },
  driverProgress: {
    header: {
      orderNumber: 'Order {{orderNumber}}',
    },
    notFound: {
      title: "We can't find this delivery.",
      message:
        'If you think this is an error, please contact the person that assigned you to this delivery, or call ezCater at',
    },
    pickup: {
      title: 'Pickup',
      card: {
        expandLabel: 'View Items',
        collapseLabel: 'Hide Items',
      },
    },
    dropoff: {
      title: 'Drop-off',
      miles: '{{miles}} miles',
      minutes: '{{minutes}} min',
    },
    deliveryAddressCard: {
      title: {
        pickup: 'Pickup',
        dropoff: 'Drop-off',
      },
      expandable: {
        expandPickupLabel: 'View pickup instructions',
        collapsePickupLabel: 'Hide pickup instructions',
        expandDropoffLabel: 'View drop-off instructions',
        collapseDropoffLabel: 'Hide drop-off instructions',
      },
    },
    orderItemsCard: {
      title: 'Order Details',
      subtitle_one: '{{count}} Item',
      subtitle_other: '{{count}} Items',
      expandable: {
        expandLabel: 'View Items',
        collapseLabel: 'Hide Items',
      },
      list: {
        label: 'Order Items',
      },
    },
    actionButtons: {
      error: 'Error',
      uncaughtError: 'Something went wrong',
    },
    startDelivery: {
      buttonLabel: "I'm on my way",
      submittingButtonLabel: 'Starting delivery...',
      tooEarly: 'Delivery can only be started within 2 hours of delivery time.',
    },
    completeDelivery: {
      buttonLabel: 'Delivery is done',
      submittingButtonLabel: 'Completing...',
    },
    contactSection: {
      driverAskFor: 'Upon Delivery Ask For',
      dropoffInstructions: 'Delivery Instructions',
      pickupInstructions: 'Pickup Instructions',
    },
    callCard: {
      questionsOrIssues: 'Any questions or issues?',
      callCustomer: 'Call Customer',
      callCustomerDisabledReason: 'Customer does not have a valid phone number',
      callEzCater: 'Call ezCater',
      error: {
        headline: 'Error',
        message:
          'There was an error requesting the customers phone number.  Please try again at a later time.',
      },
    },
  },
  selfAssignRegistration: {
    title: 'Sign Up - ezCater Delivery',
    header: 'Please add your name',
    subheader:
      'We only ever use your name in case we need to communicate with you, or the customer. This just makes the process a little easier for everyone and you only need to do it once.',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    firstNamePlaceholder: 'Jane',
    lastNamePlaceholder: 'Doe',
    error: 'There was an error requesting saving this information. Please try again.',
    button: 'Assign myself to the order',
    disclaimer:
      'We may send you informational and transactional text messages such as delivery information.',
    terms: 'Terms apply.',
  },
  complianceModal: {
    header: 'SMS messages are required in order for us to send you further delivery information.',
    subheader:
      'We will use {{phone}} to send you transactional texts, such as about orders you are assigned to.',
    terms: 'Terms apply.',
    button: 'Continue',
    error: 'There was an error saving this information. Please try again.',
  },
} as const;
