import Environment from '@ezcater/env-vars';
import {datadogRum} from '@datadog/browser-rum';
import {scrubUrlQueryParams} from 'util/urlUtils';

const SENSITIVE_APP_URL_QUERY_PARAMS = ['t'];

const initializeDatadogRum = (): void => {
  const applicationId = Environment.fetch('DATADOG_RUM_APP_ID', null);
  const clientToken = Environment.fetch('DATADOG_RUM_CLIENT_ID', null);

  if (!applicationId || !clientToken) {
    console.log('skipping Datadog RUM due to missing credentials.');
    return;
  }

  datadogRum.init({
    applicationId,
    clientToken,
    env: Environment.fetch('SENTRY_ENVIRONMENT'),
    version: Environment.fetch('COMMIT_HASH'),
    service: 'Delivery Management React',
    site: 'datadoghq.com',
    trackInteractions: true,
    sampleRate: 100,
    beforeSend: event => {
      if (event.type === 'view') {
        const url = new URL(event.view.url);
        // eslint-disable-next-line no-param-reassign
        event.view.url = scrubUrlQueryParams(url, SENSITIVE_APP_URL_QUERY_PARAMS).toString();
        // eslint-disable-next-line no-param-reassign
        event.view.referrer = scrubUrlQueryParams(url, SENSITIVE_APP_URL_QUERY_PARAMS).toString();
      }

      if (event.type === 'resource') {
        const url = new URL(event.resource.url);
        // eslint-disable-next-line no-param-reassign
        event.resource.url = scrubUrlQueryParams(url, SENSITIVE_APP_URL_QUERY_PARAMS).toString();
      }

      if (event.type === 'error' && event.error.resource?.url) {
        const url = new URL(event.error.resource.url);
        // eslint-disable-next-line no-param-reassign
        event.error.resource.url = scrubUrlQueryParams(
          url,
          SENSITIVE_APP_URL_QUERY_PARAMS
        ).toString();
      }
    },
  });
};

export default initializeDatadogRum;
