export type Address = {
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
};

/**
 * Formats an address
 * @param address
 * @returns
 */
export default function addressFormatter<T extends Address>(address?: T | null) {
  if (!address) {
    return '';
  }
  return `${address?.street1}${address?.street2 ? ' ' : ''}${
    address?.street2 ?? ''
  }, ${address?.city}, ${address?.state} ${address?.zip}`;
}
