import gql from 'graphql-tag';

const DriverFeedback = gql(/* GraphQL */ `
  mutation DriverFeedback($input: DriverFeedbackInput!) {
    driverFeedback(input: $input) {
      errors
    }
  }
`);

export default DriverFeedback;
