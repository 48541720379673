import {useTranslation} from 'react-i18next';
import styled from '@emotion/styled';
import {EzLink} from '@ezcater/recipe';
import PhoneNumbers from 'constants/PhoneNumbers';
import {trackButtonClick} from 'util/tracking/trackEvent';
import {SMS_OPT_IN_PHONE_NUMBER_BUTTON} from 'util/tracking/trackingEvents';

const StyledTitleContainer = styled.div`
  background-color: #00b373;
  border-radius: 8px;
  color: #f5f6f8;
  margin: 16px;
  padding: 0px 16px 16px 16px;
`;

const StyledTitleHeader = styled.h3`
  font-weight: 700;
  display: inline-block;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const StyledTitleSubheader = styled.span`
  display: inline-block;
  font-size: 16px;
`;

const StyledSuggestionsContainer = styled.div`
  color: #1d3c34;
  margin: 24px;
`;

const StyledSuggestionsHeader = styled.div`
  font-weight: 700;
`;

const StyledSuggestion = styled.div`
  margin-top: 20px;
`;

const RequestSignInLinkSuccess = () => {
  const {t} = useTranslation();

  const titleHeader = t('requestSignInLinkSuccess.titleHeader');
  const titleSubheader = t('requestSignInLinkSuccess.titleSubheader');
  const suggestionsHeader = t('requestSignInLinkSuccess.suggestionsHeader');
  const suggestionsSubheader = t('requestSignInLinkSuccess.suggestionsSubheader');
  const suggestionOne = t('requestSignInLinkSuccess.suggestionOne');
  const suggestionTwo = t('requestSignInLinkSuccess.suggestionTwo');

  const trackOptInNumberClick = () => {
    trackButtonClick(SMS_OPT_IN_PHONE_NUMBER_BUTTON, {
      driver_id: null,
      delivery_id: null,
    });
  };

  return (
    <>
      <StyledTitleContainer>
        <StyledTitleHeader>{titleHeader}</StyledTitleHeader>
        <StyledTitleSubheader>{titleSubheader}</StyledTitleSubheader>
      </StyledTitleContainer>
      <StyledSuggestionsContainer>
        <StyledSuggestionsHeader>{suggestionsHeader}</StyledSuggestionsHeader>
        <div>{suggestionsSubheader}</div>
        <StyledSuggestion>1. {suggestionOne} </StyledSuggestion>
        <StyledSuggestion>
          2. {suggestionTwo}{' '}
          <EzLink
            href={`sms:${PhoneNumbers.TWILIO_SMS_OPT_IN};?&body=START`}
            onClick={trackOptInNumberClick}
          >
            {PhoneNumbers.TWILIO_SMS_OPT_IN}
          </EzLink>
          .
        </StyledSuggestion>
      </StyledSuggestionsContainer>
    </>
  );
};

export default RequestSignInLinkSuccess;
