import gql from 'graphql-tag';

const Me = gql(/* GraphQL */ `
  query Me {
    me: dmMe {
      id
      firstName
      lastName
      phone
      smsOptInStatus
    }
  }
`);

export default Me;
