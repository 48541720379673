import {EzLayout} from '@ezcater/recipe';
import {formatPhoneNumber} from 'util/StringUtils';
import {useTranslation} from 'react-i18next';
import {FooterWrapper, Copyright, FooterLink} from './Footer.styles';

type FooterProps = {
  phone: string;
};

const Footer = ({phone}: FooterProps) => {
  const {t} = useTranslation();

  return (
    <FooterWrapper>
      <EzLayout layout="stack" alignX="center">
        <div>
          <span>{t('footer.questions')}</span>
          &nbsp;
          <FooterLink href={`tel:${phone}`}>{formatPhoneNumber(phone)}</FooterLink>
        </div>
        <div>
          <FooterLink href="/privacy_policy.html" target="_blank">
            {t('footer.privacyPolicy')}
          </FooterLink>
          &nbsp;&nbsp;&nbsp;
          <FooterLink href="/ca_notice_of_collection.html" target="_blank">
            {t('footer.caNoticeOfCollection')}
          </FooterLink>
        </div>
        <Copyright>{t('footer.copyright')}</Copyright>
      </EzLayout>
    </FooterWrapper>
  );
};

export default Footer;
