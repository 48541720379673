import styled from '@emotion/styled';

export const ContentsContainer = styled('div', {label: 'ContentsContainer'})`
  background-color: #f5f6f8;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  font-size: ${({theme}) => theme.fontSizes[300]};
  h3 {
    font-size: ${({theme}) => theme.fontSizes[300]};
  }
`;

export const DriverProgressContainer = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

export const ScrollBarContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ScrollBarContent = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const DeliveryCompletedAlertWrapper = styled.div`
  margin-top: 4px;
`;

export const SummaryBarTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00b06e;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: ${({theme}) => theme.fontSizes[500]};
  color: ${({theme}) => theme.colors.white};
  font-weight: ${({theme}) => theme.fontWeights.bold};
  margin-bottom: ${({theme}) => theme.recipeSpacing.xs2};

  ${({theme}) => theme.breakpoints.down('md')} {
    font-size: ${({theme}) => theme.fontSizes[500]};
  }
`;
