import gql from 'graphql-tag';

const DeliveryMetadata = gql(/* GraphQL */ `
  query DeliveryMetadata($id: ID!) {
    deliveryMetadata(id: $id) {
      assignable
      unassignableReason
    }
  }
`);

export default DeliveryMetadata;
