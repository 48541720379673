import {FormEvent, useState} from 'react';
import {useMutation} from 'services/apollo';
import {EzPage, EzCard, EzHeading, EzField, EzLink} from '@ezcater/recipe';
import {RequestMagicLink} from 'services/graphql';
import {formatPhoneNumber} from 'util/StringUtils';
import Button from 'components/Button';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import {usePageViewTracking} from 'util/tracking';
import {EXPIRED_MAGIC_LINK} from 'util/tracking/trackingEvents';
import type {
  MagicLinkRequestMutation,
  MagicLinkRequestMutationVariables,
  SelfAssignSource,
} from 'services/graphql/types';
import {TERMS_URL} from 'paths';
import Layout from '../Layout';

const ExpiredMagicLink = () => {
  usePageViewTracking(EXPIRED_MAGIC_LINK);
  const history = useHistory();
  const {t} = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');

  const {search} = history.location;
  const parsedQueryString = queryString.parse(search);
  const driverAssignmentId = parsedQueryString.driverAssignmentId as string | undefined;
  const deliveryId = parsedQueryString.deliveryId as string | undefined;
  const source = parsedQueryString.source as SelfAssignSource | undefined;

  const [magicLinkRequestQuery, magicLinkRequestData] = useMutation<
    MagicLinkRequestMutation,
    MagicLinkRequestMutationVariables
  >(RequestMagicLink);

  const onChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await magicLinkRequestQuery({
        variables: {
          input: {
            phone: phoneNumber,
            driverAssignmentId,
            deliveryId,
            source,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const defaultError = t('expiredMagicLink.error');

  const getError = () => {
    if (!magicLinkRequestData.loading) {
      if (magicLinkRequestData.error) {
        return defaultError;
      }

      if (magicLinkRequestData?.data?.magicLinkRequest?.errors?.length) {
        return magicLinkRequestData?.data?.magicLinkRequest?.errors[0].message;
      }
    }
  };

  const title = t('expiredMagicLink.title');
  const defaultHeader = t('expiredMagicLink.header');
  const defaultSubheader = t('expiredMagicLink.subheader');
  const phoneNumberText = t('expiredMagicLink.phoneNumber');
  const phoneNumberPlaceholder = t('expiredMagicLink.phoneNumberPlaceholder');
  const buttonText = t('expiredMagicLink.button');
  const disclaimer = t('expiredMagicLink.disclaimer');
  const terms = t('expiredMagicLink.terms');
  const successHeader = t('expiredMagicLink.successHeader');
  const successSubheader = t('expiredMagicLink.successSubheader');

  const error = getError();
  const requestComplete =
    !magicLinkRequestData.loading && magicLinkRequestData.data?.magicLinkRequest?.success;

  const showSuccessMessages = requestComplete && !error;
  const header = showSuccessMessages ? successHeader : defaultHeader;
  const subheader = showSuccessMessages ? successSubheader : defaultSubheader;

  return (
    <Layout title={title}>
      <EzPage>
        <EzHeading size={'3'}>{header}</EzHeading>
        <EzHeading size={'4'}>{subheader}</EzHeading>
        {!showSuccessMessages && (
          <form onSubmit={handleSubmit}>
            <EzCard>
              {error && <>{error}</>}
              {!showSuccessMessages && (
                <>
                  <EzField
                    type="text"
                    label={phoneNumberText}
                    value={formatPhoneNumber(phoneNumber)}
                    onChange={e => onChange(e.currentTarget.value)}
                    placeholder={phoneNumberPlaceholder}
                  />
                  <Button
                    type="submit"
                    buttonType="primary"
                    buttonText={buttonText}
                    loading={magicLinkRequestData.loading}
                    disabled={phoneNumber === ''}
                  />

                  <EzHeading size="6">
                    {disclaimer}&nbsp;&nbsp;
                    <EzLink href={TERMS_URL} target="_blank">
                      {terms}
                    </EzLink>
                  </EzHeading>
                </>
              )}
            </EzCard>
          </form>
        )}
      </EzPage>
    </Layout>
  );
};

export default ExpiredMagicLink;
