import gql from 'graphql-tag';

// The operation name of this mutation MUST be MagicLinkDriverSessionCreate
// so that the operation name allowlist works properly in dm-rails
const MagicLinkDriverSessionCreate = gql(/* GraphQL */ `
  mutation MagicLinkDriverSessionCreate($input: MagicLinkDriverSessionCreateInput!) {
    magicLinkDriverSessionCreate(input: $input) {
      success
    }
  }
`);

export default MagicLinkDriverSessionCreate;
