import {EzHeading} from '@ezcater/recipe';
import LoadingSpinner from 'components/LoadingSpinner';
import DeliveryCard from 'components/DeliveryCard';
import type {DriverAssignmentsQuery} from 'services/graphql/types';
import {CardContainer} from './DeliveryCardContainer.styles';

interface DeliveryCardProps {
  data?: DriverAssignmentsQuery;
  loading: boolean;
  header: string;
}

const DeliveryCardContainer = ({data, loading, header}: DeliveryCardProps) => (
  <CardContainer>
    <EzHeading size="2">{header}</EzHeading>
    {loading && !data && <LoadingSpinner />}
    {!loading && data && data?.driverAssignments && (
      <>
        {data?.driverAssignments.length === 0 && <p>No Deliveries Available</p>}
        {data?.driverAssignments.length >= 1 &&
          data?.driverAssignments.map(driverAssignment => (
            <DeliveryCard key={driverAssignment.id} assignment={driverAssignment} />
          ))}
      </>
    )}
  </CardContainer>
);

export default DeliveryCardContainer;
