import {createTracker} from '@ezcater/tracker-js';
import trackingMetadata from './trackingMetadata';
import eventDictionary from '../../eventDictionary.json'; // eslint-disable-line import/extensions

export const BUTTON_CLICK_EVENT = 'button clicked';
export const DROPDOWN_CLICK_EVENT = 'dropdown clicked';
export const LINK_CLICK_EVENT = 'link clicked';
export const PAGE_VIEW_EVENT = 'page viewed';

// create the tracker with "ssr = true" to get around the browser's COR blocking
export const tracker = createTracker(eventDictionary, {ssr: true});

interface MetadataType {
  value?: string;
  delivery_id?: string | null;
  driver_id?: string | null;
  misc_json?: string | null;
}

const trackEvent = async (eventName: string, metadata: MetadataType = {}) => {
  try {
    await tracker.track(eventName, {
      project: 'delivery-management',
      ...trackingMetadata(),
      ...metadata,
      // tracker only accepts null, not undefined...
      delivery_id: metadata.delivery_id || null,
      driver_id: metadata.driver_id || null,
      misc_json: metadata.misc_json || null,
    });
  } catch (error) {
    console.error('Failed to generate tracking event');
  }
};

export const trackButtonClick = (buttonName: string, metadata: MetadataType) => {
  return trackEvent(BUTTON_CLICK_EVENT, {value: buttonName, ...metadata});
};

export const trackPageView = (pageName: string, metadata: MetadataType) => {
  return trackEvent(PAGE_VIEW_EVENT, {value: pageName, ...metadata});
};

export const trackDropdownClick = (dropdownOption: string) => {
  return trackEvent(DROPDOWN_CLICK_EVENT, {value: dropdownOption});
};

export default trackEvent;
