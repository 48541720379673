import {VFC} from 'react';
import type {DriverAssignmentQuery} from 'services/graphql/types';
import DeliveryAddressCard from '../DeliveryAddressCard';
import OrderItemsCard from '../OrderItemsCard';
import {DriverProgressContainer} from '../DriverProgress.styles';

interface Props {
  readonly data: DriverAssignmentQuery;
}

/**
 * Stage 1: click this button to begin the delivery.
 * @see https://www.figma.com/file/OtmeRPNdEEUwySX1znDSMv/Delivery-Driver-Web-%2F-Mobile?node-id=189%3A1973
 */
const StartDelivery: VFC<Props> = ({data}) => {
  const orderItems = data.driverAssignment?.delivery?.order?.orderItems ?? [];

  return (
    <DriverProgressContainer>
      <DeliveryAddressCard
        type="pickup"
        address={data?.driverAssignment?.delivery?.pickupAddress}
      />
      <DeliveryAddressCard
        type="dropoff"
        eventAt={data?.driverAssignment?.delivery?.eventAtWithPadding}
        distanceInMiles={data?.driverAssignment?.delivery.distanceInMiles ?? null}
        durationInMinutes={data?.driverAssignment?.delivery.durationInMinutes ?? null}
        address={data?.driverAssignment?.delivery?.dropoffAddress}
        driverAskFor={data?.driverAssignment?.delivery?.driverAskFor}
      />
      {!!orderItems.length && <OrderItemsCard items={orderItems} />}
    </DriverProgressContainer>
  );
};

export default StartDelivery;
