/**
 * Lightweight wrapper around local storage that lets you easily get/set a key-value pair without
 * worrying about whether the browser supports local storage or not.
 *
 * Example:
 * const lsData = localStorageData('some-key', 'some-default-value');
 *
 * // If local storage is not available or the key does not exist, returns the default value
 * const value = lsData.get();
 *
 * // Writes to local storage if available. Returns the input.
 * const newValue = lsData.set('some-new-value');
 */

const {localStorage} = window;
const localStorageData = <T>(key: string, defaultValue: T) => ({
  get: (): T => {
    const value = localStorage && localStorage.getItem(key);
    return value ? (JSON.parse(value) as T) : defaultValue;
  },
  set: (value: T): T => {
    if (localStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
    return value;
  },
});

export default localStorageData;
