import {useMemo} from 'react';
import type {ReactNode} from 'react';
import {configureScope as configureSentryScope} from '@sentry/browser';
import {datadogRum} from '@datadog/browser-rum';
import {CurrentAccountProvider} from 'components/CurrentAccount';
import LoadingSpinner from 'components/LoadingSpinner';
import {useQuery} from 'services/apollo';
import {Me} from 'services/graphql';
import type {MeQuery} from 'services/graphql/types';
import {DmDriverOptInStatus} from 'services/graphql/types';
import {FeatureFlagsProvider, createFeatureFlagsClient} from '@ezcater/feature-flags-react';
import Environment from '@ezcater/env-vars';
import SmartBanner from 'react-smartbanner';
import DriverAuthEnabledRedirect from './DriverAuthEnabledRedirect';
import ComplianceModal from '../ComplianceModal';

import 'react-smartbanner/dist/main.css';

interface CurrentAccountProviderWithQueryProps {
  redirect: boolean;
  children: ReactNode;
}

const CurrentAccountProviderWithQuery = ({
  redirect,
  children,
}: CurrentAccountProviderWithQueryProps) => {
  const meQueryResult = useQuery<MeQuery>(Me, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const userId = data?.me?.id;

      if (userId) {
        configureSentryScope(scope => {
          scope.setUser({id: userId});
        });
        datadogRum.setUser({id: userId});
      }
    },
  });
  const client = useMemo(
    () =>
      createFeatureFlagsClient({
        host: Environment.fetch('FEATURE_FLAGS_HOST'),
        environment: Environment.fetch('NODE_ENV', ''),
      }),
    []
  );

  if (meQueryResult.loading && !meQueryResult.data) {
    return <LoadingSpinner />;
  }

  const currentAccountId = meQueryResult.data?.me?.id ?? '';
  const currentAccountPhone = meQueryResult.data?.me?.phone ?? '';
  const currentAccountFirstName = meQueryResult.data?.me?.firstName ?? '';
  const currentAccountLastName = meQueryResult.data?.me?.lastName ?? '';
  const currentAccountComplianceStatus = meQueryResult.data?.me?.smsOptInStatus ?? '';

  const isComplianceModalOpen =
    meQueryResult.data?.me !== null &&
    currentAccountComplianceStatus !== DmDriverOptInStatus.OptedIn;

  return (
    <FeatureFlagsProvider
      trackingId={currentAccountId}
      allowlistValues={[`phone:${currentAccountPhone}`]}
      client={client}
    >
      <CurrentAccountProvider id={currentAccountId}>
        <DriverAuthEnabledRedirect
          id={currentAccountId}
          redirect={redirect}
          refetch={meQueryResult.refetch}
          firstName={currentAccountFirstName}
          lastName={currentAccountLastName}
        >
          <SmartBanner
            daysHidden={1}
            daysReminder={1}
            ignoreIosVersion
            title="ezManage - Delivery"
          />
          <ComplianceModal
            isOpen={isComplianceModalOpen}
            phone={currentAccountPhone}
            driverId={currentAccountId}
          />
          {children}
        </DriverAuthEnabledRedirect>
      </CurrentAccountProvider>
    </FeatureFlagsProvider>
  );
};

export default CurrentAccountProviderWithQuery;
