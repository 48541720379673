import {VFC} from 'react';
import {EzLayout} from '@ezcater/recipe';
import type {DriverAssignmentQuery} from 'services/graphql/types';
import CallCard from './CallCard';
import DeliveryAddressCard from '../DeliveryAddressCard/DeliveryAddressCard';
import OrderItemsCard from '../OrderItemsCard';
import {DriverProgressContainer} from '../DriverProgress.styles';

interface Props {
  readonly data: DriverAssignmentQuery;
}

/**
 * Step 2: Delivery is in progress
 */
const InProgress: VFC<Props> = ({data}) => {
  const orderItems = data?.driverAssignment.delivery.order?.orderItems || [];

  return (
    <DriverProgressContainer>
      <EzLayout layout="stack">
        <DeliveryAddressCard
          type="dropoff"
          isMapLinkDisabled={false}
          eventAt={data?.driverAssignment?.delivery?.eventAtWithPadding}
          distanceInMiles={data?.driverAssignment?.delivery.distanceInMiles ?? null}
          durationInMinutes={data?.driverAssignment?.delivery.durationInMinutes ?? null}
          address={data?.driverAssignment?.delivery?.dropoffAddress}
          driverAskFor={data?.driverAssignment?.delivery?.driverAskFor}
        />
        {!!orderItems.length && <OrderItemsCard items={orderItems} />}
        <CallCard
          driverAssignmentId={data?.driverAssignment?.id}
          phone={data?.driverAssignment?.delivery?.dropoffAddress?.phone}
        />
      </EzLayout>
    </DriverProgressContainer>
  );
};

export default InProgress;
