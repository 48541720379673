// Pages
export const TRACK_DRIVER_ASSIGNMENT_PAGE_NAME = 'driver-assignment';
export const SELF_ASSIGN_PAGE_NAME = 'driver-self-assign';
export const SIGN_UP_PAGE_NAME = 'driver-sign-up';
export const SIGN_UP_FULL_PAGE_NAME = 'driver-sign-up-full';
export const SIGN_IN_PAGE_NAME = 'driver-sign-in';
export const EXPIRED_MAGIC_LINK = 'expired-magic-link' as const;
export const REQUEST_SIGN_IN_LINK = 'request-sign-in-link' as const;
export const SELF_ASSIGN_REGISTRATION = 'self-assign-registration' as const;
export const COMPLIANCE_MODAL_PAGE_NAME = 'compliance-modal' as const;

// Buttons/Links
export const CALL_CUSTOMER_BUTTON = 'call-customer-button';
export const CALL_EZCATER_BUTTON = 'call-ezcater-button';
export const COMPLIANCE_MODAL_BUTTON = 'compliance-modal-button';
export const DELETE_ACCOUNT_BUTTON = 'delete-account-button';
export const SMS_OPT_IN_PHONE_NUMBER_BUTTON = 'sms-opt-in-phone-number-button';
export const DRIVER_HELP_CALL_LINK = 'driver-help-call-link';
export const DRIVER_HELP_EMAIL_LINK = 'driver-help-email-link';
