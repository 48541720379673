import {useState} from 'react';
import {EzCard, EzCardExpandable, EzCardSection, EzHeading} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import type {DriverAssignmentQuery} from 'services/graphql/types';
import {List, ListItem} from './OrderItemsCard.styles';

type OrderItems = NonNullable<
  DriverAssignmentQuery['driverAssignment']['delivery']['order']
>['orderItems'];

interface OrderItemsProps {
  items: OrderItems;
}

const OrderItemsCard = ({items}: OrderItemsProps) => {
  const {t} = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <EzCard
      expandable={{
        isExpanded,
        onClick: handleExpandToggle,
        expandLabel: t('driverProgress.orderItemsCard.expandable.expandLabel'),
        collapseLabel: t('driverProgress.orderItemsCard.expandable.collapseLabel'),
      }}
    >
      <EzCardSection>
        <div>
          <EzHeading color="green" casing="uppercase" size="5">
            {t('driverProgress.orderItemsCard.title')}
          </EzHeading>
          {!isExpanded && (
            <EzHeading color="blue" size="2">
              {t('driverProgress.orderItemsCard.subtitle', {count: items.length})}
            </EzHeading>
          )}
        </div>
      </EzCardSection>
      <EzCardExpandable isExpanded={isExpanded}>
        <EzCardSection>
          <List aria-label={t('driverProgress.orderItemsCard.list.label')}>
            {items.map(({name, quantity}) => (
              <ListItem key={name}>
                x{quantity}&nbsp;{name}
              </ListItem>
            ))}
          </List>
        </EzCardSection>
      </EzCardExpandable>
    </EzCard>
  );
};

export default OrderItemsCard;
