import {ReactNode} from 'react';
import {EzHeading, EzTextStyle, EzLink, EzCard, EzPage} from '@ezcater/recipe';
import PhoneNumbers from 'constants/PhoneNumbers';
import Layout from 'pages/Layout';

interface NotFoundProps {
  title?: string;
  children?: ReactNode;
}

const NotFound = ({title, children}: NotFoundProps) => (
  <Layout title={'Not Found - ezCater Delivery'}>
    <EzPage>
      <EzHeading size="2" as="h1">
        <EzTextStyle use="strong">{title || 'Oops, something went wrong.'}</EzTextStyle>
      </EzHeading>
      <EzCard>
        {children || (
          <>
            If you need immediate assistance, please call ezCater Support at&nbsp;
            <EzLink href={`tel:${PhoneNumbers.EZDISPATCH_DIRECT}`}>
              {PhoneNumbers.EZDISPATCH_DIRECT}
            </EzLink>
            .
          </>
        )}
      </EzCard>
    </EzPage>
  </Layout>
);

export default NotFound;
