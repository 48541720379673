import styled from '@emotion/styled';
import {keyframes} from '@emotion/core';

const keyframesSpin = () => keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  margin-top: 35%;
  width: 100%;
`;

export const Spinner = styled.div`
  position: relative;
  color: transparent;
  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 6px solid rgba(0, 0, 0, 0.15);
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    width: 64px;
    height: 64px;
    animation: ${keyframesSpin} 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 50%;
    border-color: ${({theme}) => theme.colors.grays[700]} transparent transparent;
    border-style: solid;
    border-width: 6px;
    box-shadow: 0 0 0 1px transparent;
  }
`;
