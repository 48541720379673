import {createContext} from 'react';

type CurrentAccountType = {
  id: string | null;
};

const CurrentAccountContext = createContext<CurrentAccountType>({
  id: null,
});

export default CurrentAccountContext;
