import {parseISO} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';

const dateToTimeFormatter = (
  dateOrStr: Date | string,
  timeZone: string | null | undefined,
  formatStr?: string
) => {
  const dateTime = typeof dateOrStr === 'string' ? parseISO(dateOrStr) : dateOrStr;
  return formatWithTimeZone(
    dateTime,
    timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    formatStr || 'h:mm a z'
  );
};

const formatWithTimeZone = (dateTime: Date, timeZone: string, formatStr: string) => {
  const date = utcToZonedTime(dateTime, timeZone);

  return format(date, formatStr, {timeZone});
};

export default dateToTimeFormatter;
