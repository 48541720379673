import {forwardRef} from 'react';
import type {ComponentProps} from 'react';
import {EzButton} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';

type ButtonProps = Partial<ComponentProps<typeof EzButton>>;

type Props = ButtonProps & {
  loading: boolean | undefined;
};

const CompleteDeliveryButton = forwardRef<HTMLButtonElement, Props>(({loading, ...props}, ref) => {
  const {t} = useTranslation();
  const buttonLabel = t(
    loading
      ? 'driverProgress.completeDelivery.submittingButtonLabel'
      : 'driverProgress.completeDelivery.buttonLabel'
  );
  return (
    <EzButton {...props} disabled={loading} use="primary" ref={ref} style={{padding: '1rem'}}>
      {buttonLabel}
    </EzButton>
  );
});

CompleteDeliveryButton.displayName = 'StartDelivery';

export default CompleteDeliveryButton;
