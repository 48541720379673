import {useEffect} from 'react';
import {useMutation} from 'services/apollo';
import type {ApolloQueryResult} from 'apollo-client';
import {MagicLinkDriverSessionCreate} from 'services/graphql';
import {useHistory} from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import type {
  MeQuery,
  MagicLinkDriverSessionCreateMutation,
  MagicLinkDriverSessionCreateMutationVariables,
  SelfAssignSource,
} from 'services/graphql/types';
import {EXPIRED_MAGIC_LINK_PATH} from 'paths';

type MagicLinkSessionRedirectProps = {
  token: string;
  refetch: () => Promise<ApolloQueryResult<MeQuery>>;
} & RedirectUrlProps;

type RedirectUrlProps = {
  driverAssignmentId?: string;
  deliveryId?: string;
  source?: `${SelfAssignSource}`;
};

// Expired magic link redirect urls are built using search parameters because there
// are multiple different values that could be provided, so named router params
// will not work
const buildRedirectUrl = ({driverAssignmentId, deliveryId, source}: RedirectUrlProps) => {
  if (driverAssignmentId) {
    return `${EXPIRED_MAGIC_LINK_PATH}?driverAssignmentId=${driverAssignmentId}`;
  }

  if (deliveryId || source) {
    return `${EXPIRED_MAGIC_LINK_PATH}?deliveryId=${deliveryId}&source=${source}`;
  }

  return EXPIRED_MAGIC_LINK_PATH;
};

const MagicLinkSessionRedirect = ({
  token,
  refetch,
  driverAssignmentId,
  deliveryId,
  source,
}: MagicLinkSessionRedirectProps) => {
  const history = useHistory();
  const redirectUrl = buildRedirectUrl({driverAssignmentId, deliveryId, source});

  const [magicLinkDriverSessionCreateQuery, _magicLinkDriverSessionCreateData] = useMutation<
    MagicLinkDriverSessionCreateMutation,
    MagicLinkDriverSessionCreateMutationVariables
  >(MagicLinkDriverSessionCreate, {
    onCompleted(result) {
      if (result.magicLinkDriverSessionCreate?.success) {
        refetch();
      } else {
        history.push(redirectUrl);
      }
    },
  });

  useEffect(() => {
    const createMagicLinkDriverSession = async () => {
      try {
        await magicLinkDriverSessionCreateQuery({
          variables: {
            input: {
              token,
            },
          },
        });
      } catch (error) {
        console.error(error);
        history.push(redirectUrl);
      }
    };

    createMagicLinkDriverSession();
  }, [history, magicLinkDriverSessionCreateQuery, token, redirectUrl]);

  return <LoadingSpinner />;
};

export default MagicLinkSessionRedirect;
