import i18n, {InitOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import Environment from '@ezcater/env-vars';
import en from './locales/en';

const i18nConfig: InitOptions = {
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translation: en,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  missingKeyHandler: (_lng, _ns, key) => {
    if (Environment.fetch('NODE_ENV', 'production') !== 'production') {
      throw new Error(`Missing translation: ${key}`);
    }
  },
};

i18n.use(initReactI18next).init(i18nConfig);

export default i18n;
