import {useEffect} from 'react';
import {useMutation} from 'services/apollo';
import {EzModal, EzLayout, EzHeading, EzLink} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import {formatPhoneNumber} from 'util/StringUtils';
import {trackPageView, trackButtonClick} from 'util/tracking/trackEvent';
import {COMPLIANCE_MODAL_PAGE_NAME, COMPLIANCE_MODAL_BUTTON} from 'util/tracking/trackingEvents';
import {DriverAcceptSmsTerms} from 'services/graphql';
import type {
  DriverAcceptSmsTermsMutation,
  DriverAcceptSmsTermsMutationVariables,
} from 'services/graphql/types';
import {TERMS_URL} from 'paths';
import {ErrorText} from 'styles/global.styles';

interface ComplianceModalProps {
  isOpen: boolean;
  phone: string;
  driverId: string;
}

const ComplianceModal = ({isOpen, phone, driverId}: ComplianceModalProps) => {
  const {t} = useTranslation();

  const subheaderText = t('complianceModal.subheader', {
    phone: formatPhoneNumber(phone),
  });
  const termsText = t('complianceModal.terms');
  const buttonText = t('complianceModal.button');
  const errorText = t('complianceModal.error');

  const [acceptSmsTerms, acceptSmsTermsResult] = useMutation<
    DriverAcceptSmsTermsMutation,
    DriverAcceptSmsTermsMutationVariables
  >(DriverAcceptSmsTerms, {variables: {input: {}}});

  const driverData = !!acceptSmsTermsResult.data?.driverAcceptSmsTerms?.driver;
  const hasErrors =
    !!acceptSmsTermsResult.data?.driverAcceptSmsTerms?.errors || !!acceptSmsTermsResult.error;

  const isVisible = isOpen && !driverData;

  useEffect(() => {
    if (isVisible) {
      trackPageView(COMPLIANCE_MODAL_PAGE_NAME, {
        driver_id: driverId,
        delivery_id: null,
      });
    }
  }, [isVisible, driverId]);

  if (!isVisible) {
    return null;
  }

  const onSubmit = async () => {
    try {
      await acceptSmsTerms();

      trackButtonClick(COMPLIANCE_MODAL_BUTTON, {
        driver_id: driverId,
        delivery_id: null,
      });
    } catch (error) {
      // no op
    }
  };

  return (
    <EzModal
      isOpen={isVisible}
      headerText=""
      submitLabel={buttonText}
      onSubmit={onSubmit}
      isSubmitting={acceptSmsTermsResult.loading}
    >
      <EzLayout layout={{base: 'stack', medium: 'equal'}} />
      <EzHeading size="4">
        <div style={{marginBottom: '1rem'}} />
        {subheaderText}&nbsp;&nbsp;
        <EzLink href={TERMS_URL} target="_blank">
          {termsText}
        </EzLink>
      </EzHeading>
      {hasErrors && <ErrorText>{errorText}</ErrorText>}
    </EzModal>
  );
};

export default ComplianceModal;
