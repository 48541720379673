import styled from '@emotion/styled';

export const FooterWrapper = styled.div`
  background: ${({theme}) => theme.colors.navigation.background};
  color: ${({theme}) => theme.colors.white};
  padding: 1.5em;
`;

export const FooterLink = styled.a`
  text-decoration: underline;
  color: ${({theme}) => theme.colors.white};
`;

export const Copyright = styled.div`
  color: ${({theme}) => theme.colors.text.deemphasis};
  margin-top: 5%;
`;
