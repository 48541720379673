import gql from 'graphql-tag';

const CreateDeliveryEvent = gql(/* GraphQL */ `
  mutation CreateDeliveryEvent($input: CreateDeliveryEventInput!) {
    createDeliveryEvent(input: $input) {
      errors
      driverAssignment {
        id
        deliveryStatus
      }
    }
  }
`);

export default CreateDeliveryEvent;
