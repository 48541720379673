import {ComponentPropsWithoutRef} from 'react';
import {EzButton} from '@ezcater/recipe';
import {ButtonWrapper} from './Button.styles';

type ButtonProps = Omit<ComponentPropsWithoutRef<typeof EzButton>, 'use'> & {
  buttonText: string;
  buttonType: ComponentPropsWithoutRef<typeof EzButton>['use'];
  testId?: string;
  smallText?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
};

const Button = (
  {
    onClick,
    buttonText,
    buttonType,
    testId,
    disabled,
    disabledMessage,
    smallText,
    loading,
    fullWidth,
    type = 'button',
  }: ButtonProps // When writing specs use data-testid for confirming existence of button but not for clicking (getByRole instead)
) => (
  <ButtonWrapper data-testid={testId} smallText={smallText} fullWidth={fullWidth}>
    <EzButton
      type={type}
      use={buttonType}
      onClick={onClick}
      disabled={disabled === true}
      loading={loading}
      disabledMessage={disabledMessage}
    >
      {buttonText}
    </EzButton>
  </ButtonWrapper>
);

export default Button;
