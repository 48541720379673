import Environment from '@ezcater/env-vars';
import * as Sentry from '@sentry/browser'; // eslint-disable-line import/no-namespace
import {scrubUrlQueryParams} from 'util/urlUtils';

const SENSITIVE_APP_URL_QUERY_PARAMS = ['t'];

const sentryDsn = Environment.fetch('SENTRY_DSN', null);
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: Environment.fetch('SENTRY_ENVIRONMENT'),
    release: Environment.fetch('COMMIT_HASH'),
    allowUrls: [/ezcater\.com/],
    beforeSend: event => {
      const isAppUrl = !!event.request?.url?.includes(window.location.origin);

      if (event.request?.url && isAppUrl) {
        const url = new URL(event.request.url);
        // eslint-disable-next-line no-param-reassign
        event.request.url = scrubUrlQueryParams(url, SENSITIVE_APP_URL_QUERY_PARAMS).toString();
      }

      return event;
    },
  });
}
