import gql from 'graphql-tag';

const DestroyDriverSession = gql(/* GraphQL */ `
  mutation DestroyDriverSession($input: DestroyDriverSessionInput!) {
    destroyDriverSession(input: $input) {
      clientMutationId
    }
  }
`);

export default DestroyDriverSession;
