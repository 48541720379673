import {EzModal, EzButton, EzFooter, EzLayout, EzContent} from '@ezcater/recipe';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useFlashMessages} from '@ezcater/flash-messages';

import {apolloClient, useMutation} from 'services/apollo';
import {REQUEST_SIGN_IN_LINK_PATH} from 'paths';
import {
  DriverAccountSelfDeleteMutation,
  DriverAccountSelfDeleteMutationVariables,
} from 'services/graphql/types';
import {DeleteDriverAccountSelf} from 'services/graphql';
import {DELETE_ACCOUNT_BUTTON} from 'util/tracking/trackingEvents';
import {trackButtonClick} from 'util/tracking';
import {useCurrentAccount} from 'components/CurrentAccount';

type AccountDeletionModalProps = {
  dismissModal: () => void;
};

const AccountDeletionModal = ({dismissModal}: AccountDeletionModalProps) => {
  const history = useHistory();
  const {addFlashMessage} = useFlashMessages();
  const {id: driverId} = useCurrentAccount();
  const {t} = useTranslation();
  const [deleteAccount] = useMutation<
    DriverAccountSelfDeleteMutation,
    DriverAccountSelfDeleteMutationVariables
  >(DeleteDriverAccountSelf, {
    variables: {input: {}},
  });

  const dismissButtonText = t('accountDeletionModal.buttons.dismiss');
  const submitButtonText = t('accountDeletionModal.buttons.submit');
  const titleText = t('accountDeletionModal.title');
  const disclaimerText = t('accountDeletionModal.disclaimer');
  const successText = t('accountDeletionModal.success');
  const failureText = t('accountDeletionModal.failure');

  const handleMutationError = () => {
    dismissModal();
    addFlashMessage({
      type: 'error',
      message: failureText,
    });
  };

  const handleAccountDelete = async () => {
    try {
      const result = await deleteAccount();

      if (
        !result.data?.driverAccountSelfDelete?.driver?.id &&
        !result.data?.driverAccountSelfDelete?.errors
      ) {
        apolloClient.resetStore();
        addFlashMessage({
          type: 'success',
          message: successText,
          onRedirect: true,
        });
        history.replace(REQUEST_SIGN_IN_LINK_PATH);
      } else {
        handleMutationError();
      }
    } catch (error) {
      handleMutationError();
    } finally {
      trackButtonClick(DELETE_ACCOUNT_BUTTON, {
        driver_id: driverId,
        delivery_id: null,
      });
    }
  };

  return (
    <EzModal isOpen headerText={titleText}>
      <EzContent>{disclaimerText}</EzContent>
      <EzFooter>
        <EzLayout layout="split">
          <EzButton onClick={dismissModal} use="tertiary">
            {dismissButtonText}
          </EzButton>
          <EzButton onClick={handleAccountDelete} destructive use="primary">
            {submitButtonText}
          </EzButton>
        </EzLayout>
      </EzFooter>
    </EzModal>
  );
};

export default AccountDeletionModal;
