import styled from '@emotion/styled';

export const List = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style-type: none;
`;

export const ListItem = styled.li`
  font-size: 1rem;
  color: var(--colors-grey-800);
`;
