import styled from '@emotion/styled';

export const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  margin-bottom: 8px;

  & button {
    width: 100%;
  }
`;
