import {createContext} from 'react';

type CurrentAssignmentType = {
  id: string | null | undefined;
  deliveryId: string | null | undefined;
  driverId: string | null | undefined;
  orderNumber: string | null | undefined;
};

const CurrentAssignmentContext = createContext<CurrentAssignmentType>({
  deliveryId: null,
  id: null,
  driverId: null,
  orderNumber: null,
});

export default CurrentAssignmentContext;
