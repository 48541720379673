import type {VFC} from 'react';
import {useTranslation} from 'react-i18next';
import {EzTextStyle} from '@ezcater/recipe';
import type {DmDelivery} from 'services/graphql/types';

type Props = Partial<Pick<DmDelivery, 'durationInMinutes' | 'distanceInMiles'>>;

const DistanceAndDuration: VFC<Props> = ({distanceInMiles, durationInMinutes}) => {
  const {t} = useTranslation();
  if (!distanceInMiles || !durationInMinutes) {
    return null;
  }
  return (
    <EzTextStyle>
      {t('driverProgress.dropoff.minutes', {minutes: durationInMinutes})}
      &nbsp;&#47;&nbsp;
      {t('driverProgress.dropoff.miles', {miles: distanceInMiles})}
    </EzTextStyle>
  );
};

export default DistanceAndDuration;
