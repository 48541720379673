import gql from 'graphql-tag';

const RequestAccountCreate = gql(/* GraphQL */ `
  mutation RequestAccountCreate($input: RequestAccountCreateInput!) {
    requestAccountCreate(input: $input) {
      phone
    }
  }
`);

export default RequestAccountCreate;
