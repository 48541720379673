import {EzHeading, EzLayout} from '@ezcater/recipe';
import {VFC} from 'react';
import {useTranslation} from 'react-i18next';
import DistanceAndDuration from './DistanceAndDuration';

interface Props {
  type: 'pickup' | 'dropoff';
  distanceInMiles?: number | null;
  durationInMinutes?: number | null;
}

const CardHeader: VFC<Props> = ({type, distanceInMiles, durationInMinutes}) => {
  const {t} = useTranslation();
  const title = t(
    type === 'pickup'
      ? 'driverProgress.deliveryAddressCard.title.pickup'
      : 'driverProgress.deliveryAddressCard.title.dropoff'
  );

  return (
    <EzLayout layout="split">
      <EzHeading color="green" casing="uppercase" size="5">
        {title}
      </EzHeading>
      <DistanceAndDuration
        distanceInMiles={distanceInMiles}
        durationInMinutes={durationInMinutes}
      />
    </EzLayout>
  );
};

export default CardHeader;
