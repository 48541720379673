import gql from 'graphql-tag';

const DeleteDriverAccountSelf = gql(/* GraphQL */ `
  mutation DriverAccountSelfDelete($input: DriverAccountSelfDeleteInput!) {
    driverAccountSelfDelete(input: $input) {
      errors
      driver {
        id
      }
    }
  }
`);

export default DeleteDriverAccountSelf;
