import {EzFlashMessage} from '@ezcater/recipe';
import {FlashMessageTemplate} from '@ezcater/flash-messages';

const FlashMessage: FlashMessageTemplate = ({key, dismiss, type, message, headline}) => (
  <EzFlashMessage key={key} use={type} onDismiss={dismiss} headline={headline}>
    {message}
  </EzFlashMessage>
);

export default FlashMessage;
