import {EzAppLayout} from '@ezcater/recipe';
import {ThemeProvider} from '@emotion/react';
import Routes from './Routes';
import theme from './theme';
import './i18n';

const App = () => (
  <ThemeProvider theme={theme}>
    <EzAppLayout>
      <Routes />
    </EzAppLayout>
  </ThemeProvider>
);

export default App;
