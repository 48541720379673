import {EzButton} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import {trackButtonClick} from 'util/tracking';
import {CALL_EZCATER_BUTTON} from 'util/tracking/trackingEvents';
import PhoneNumbers from 'constants/PhoneNumbers';

interface CallEzCaterButtonProps {
  readonly driverAssignmentId: string;
  readonly driverId: string | null;
}

const CallEzCaterButton = ({driverAssignmentId, driverId}: CallEzCaterButtonProps) => {
  const {t} = useTranslation();

  const callEzCater = () => {
    window.location.assign(`tel:${PhoneNumbers.EZDISPATCH_DIRECT}`);
    trackButtonClick(CALL_EZCATER_BUTTON, {
      driver_id: driverId,
      misc_json: JSON.stringify({
        driverAssignmentId,
      }),
    });
  };

  return (
    <EzButton onClick={callEzCater} use="secondary" style={{padding: '1rem'}}>
      {t('driverProgress.callCard.callEzCater')}
    </EzButton>
  );
};

CallEzCaterButton.displayName = 'CallEzCaterButton';

export default CallEzCaterButton;
