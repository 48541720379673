import {FormEvent, useState} from 'react';
import {EzFormLayout, EzField, EzButton, EzLayout} from '@ezcater/recipe';
import {useMutation} from 'services/apollo';
import {useTranslation} from 'react-i18next';
import {useFlashMessages} from '@ezcater/flash-messages';
import {useHistory} from 'react-router-dom';
import DriverFeedback from 'services/graphql/DriverFeedback';
import type {DriverFeedbackMutation, DriverFeedbackMutationVariables} from 'services/graphql/types';
import {DELIVERIES_PATH} from 'paths';
import {DriverAssignment} from 'services/graphql';
import {StickyFooter} from './FeedbackForm.styles';

const OTHER_DELIVERY_LOCATION = 'Other';

const useFeedbackMutation = (variables: DriverFeedbackMutationVariables) => {
  const history = useHistory();
  const {addFlashMessage} = useFlashMessages();
  const {t} = useTranslation();
  return useMutation<DriverFeedbackMutation, DriverFeedbackMutationVariables>(DriverFeedback, {
    variables,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: DriverAssignment,
        variables: {
          id: variables?.input?.driverAssignmentId,
        },
      },
    ],
    onCompleted(data) {
      if (data?.driverFeedback?.errors?.length) {
        data.driverFeedback.errors.forEach(error => {
          addFlashMessage({
            type: 'error',
            message: error,
          });
        });
      } else {
        history.push(DELIVERIES_PATH);
      }
    },
    onError(_err) {
      addFlashMessage({
        type: 'error',
        message: t('feedbackForm.genericErrorMessage'),
      });
    },
  });
};

type FeedbackFormProps = {
  driverAssignmentId: string;
};

const FeedbackForm = ({driverAssignmentId}: FeedbackFormProps) => {
  const {t} = useTranslation();
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState('');
  const [otherDeliveryLocation, setOtherDeliveryLocation] = useState('');
  const [message, setMessage] = useState('');
  const [mutate, {loading}] = useFeedbackMutation({
    input: {
      driverAssignmentId,
      message,
      dropoffLocation:
        selectedDeliveryLocation !== OTHER_DELIVERY_LOCATION
          ? selectedDeliveryLocation
          : otherDeliveryLocation,
    },
  });

  const dropoffLocationLabel = t('feedbackForm.labels.dropoffLocation');
  const otherDropoffLocationLabel = t('feedbackForm.labels.otherDropoffLocation');
  const kitchenLabel = t('feedbackForm.labels.kitchen');
  const receptionLabel = t('feedbackForm.labels.reception');
  const otherLabel = t('feedbackForm.labels.other');
  const messageLabel = t('feedbackForm.labels.message');
  const submitLabel = t('feedbackForm.labels.submit');
  const messagePlaceholder = t('feedbackForm.placeholders.message');
  const otherDropoffLocationPlaceholder = t('feedbackForm.placeholders.otherDropoffLocation');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate();
  };

  return (
    <form onSubmit={handleSubmit}>
      <EzFormLayout>
        <div>
          <EzLayout layout="stack">
            <EzField
              type="radio"
              bordered
              label={dropoffLocationLabel}
              options={[
                {label: kitchenLabel, value: kitchenLabel},
                {label: receptionLabel, value: receptionLabel},
                {label: otherLabel, value: OTHER_DELIVERY_LOCATION},
              ]}
              value={selectedDeliveryLocation}
              onChange={e => setSelectedDeliveryLocation(e.target.value)}
            />
            {selectedDeliveryLocation === OTHER_DELIVERY_LOCATION && (
              <EzField
                type="text"
                label={otherDropoffLocationLabel}
                labelHidden
                placeholder={otherDropoffLocationPlaceholder}
                value={otherDeliveryLocation}
                onChange={e => setOtherDeliveryLocation(e.target.value)}
              />
            )}
            <EzField
              type="textarea"
              label={messageLabel}
              placeholder={messagePlaceholder}
              onChange={e => setMessage(e.target.value)}
            />
          </EzLayout>
        </div>
        <StickyFooter>
          <EzButton disabled={loading} use="primary" type="submit">
            {submitLabel}
          </EzButton>
        </StickyFooter>
      </EzFormLayout>
    </form>
  );
};

export default FeedbackForm;
