import {VFC} from 'react';
import {useQuery} from 'services/apollo';
import {useParams, Redirect} from 'react-router-dom';
import pathEq from 'lodash/fp/pathEq';
import {complement, anyPass} from 'lodash/fp';
import {DriverAssignment} from 'services/graphql';
import {formatOrderNumber} from 'util/StringUtils';
import LoadingSpinner from 'components/LoadingSpinner';
import NotFound from 'components/NotFound';
import {EzAlert, EzBlankState, EzLayout, EzLink} from '@ezcater/recipe';
import PhoneNumbers from 'constants/PhoneNumbers';
import {FlashMessages} from '@ezcater/flash-messages';
import {TRACK_DRIVER_ASSIGNMENT_PAGE_NAME} from 'util/tracking/trackingEvents';
import {useDriverPageViewTracking} from 'util/tracking';
import {useTranslation} from 'react-i18next';
import {DELIVERIES_PATH} from 'paths';
import {useCurrentAccount} from 'components/CurrentAccount';
import {DmDeliveryEventTypes} from 'services/graphql/types';
import type {DriverAssignmentQuery, DriverAssignmentQueryVariables} from 'services/graphql/types';
import FeedbackForm from 'components/FeedbackForm';
import {StickyFooter} from 'components/FeedbackForm/FeedbackForm.styles';
import Layout from '../Layout';
import {
  ContentsContainer,
  ScrollBarContainer,
  ScrollBarContent,
  SummaryBarTitleContainer,
  DeliveryCompletedAlertWrapper,
} from './DriverProgress.styles';
import ActionButton from './ActionButton';
import StartDelivery from './StartDelivery';
import InProgress from './InProgress';

const statusEquals = pathEq(['driverAssignment', 'deliveryStatus']);

const isAssigned = statusEquals(DmDeliveryEventTypes.DriverAssigned);
const isPickedUp = statusEquals(DmDeliveryEventTypes.DriverPickedUp);
const isDroppedOff = statusEquals(DmDeliveryEventTypes.DriverDroppedOff);
const isFeedbackProvided = statusEquals(DmDeliveryEventTypes.DriverProvidedFeedback);
const isInvalidStatus = complement(
  anyPass([isAssigned, isPickedUp, isDroppedOff, isFeedbackProvided])
);

const NotFoundPage: VFC = () => {
  const {t} = useTranslation();
  return (
    <NotFound title={t('driverProgress.notFound.title')}>
      {t('driverProgress.notFound.message')}
      <EzLink href={`tel:${PhoneNumbers.EZDISPATCH_DIRECT}`}>
        {PhoneNumbers.EZDISPATCH_DIRECT}
      </EzLink>
      .
    </NotFound>
  );
};

const DriverProgress = () => {
  const {t} = useTranslation();
  const {driverAssignmentId} = useParams<{driverAssignmentId: string}>();
  const currentAccount = useCurrentAccount();
  const currentAccountId = currentAccount.id || undefined;
  const {data, loading, error} = useQuery<DriverAssignmentQuery, DriverAssignmentQueryVariables>(
    DriverAssignment,
    {variables: {id: driverAssignmentId}}
  );
  const orderNumber = formatOrderNumber(data?.driverAssignment?.delivery?.orderNumber);

  useDriverPageViewTracking(
    TRACK_DRIVER_ASSIGNMENT_PAGE_NAME,
    data?.driverAssignment?.delivery?.id,
    currentAccountId
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !data?.driverAssignment) {
    return <NotFoundPage />;
  }

  return (
    <Layout
      title={`${orderNumber} - ezCater Delivery`}
      driverAssignmentId={driverAssignmentId}
      driverId={currentAccountId}
      deliveryId={data?.driverAssignment?.delivery?.id}
      orderNumber={orderNumber}
    >
      <ContentsContainer>
        <FlashMessages />
        <ScrollBarContainer>
          {orderNumber && (
            <SummaryBarTitleContainer>
              {t('driverProgress.header.orderNumber', {orderNumber})}
            </SummaryBarTitleContainer>
          )}
          <ScrollBarContent>
            {isAssigned(data) && <StartDelivery data={data} />}
            {isPickedUp(data) && <InProgress data={data} />}
            {isDroppedOff(data) && (
              <EzLayout layout="stack">
                <DeliveryCompletedAlertWrapper>
                  <EzAlert use="success" headline="Delivery Completed" />
                </DeliveryCompletedAlertWrapper>
                <FeedbackForm driverAssignmentId={driverAssignmentId} />
              </EzLayout>
            )}
            {isFeedbackProvided(data) && (
              <EzLayout layout="stack">
                <EzBlankState title="Feedback received" message="Thank you for your feedback" />
              </EzLayout>
            )}
            {isInvalidStatus(data) && <Redirect to={DELIVERIES_PATH} />}
          </ScrollBarContent>
          <StickyFooter>
            <ActionButton
              eventAt={data?.driverAssignment?.delivery?.eventAtWithPadding}
              driverAssignmentId={driverAssignmentId}
              status={data?.driverAssignment?.deliveryStatus}
            />
          </StickyFooter>
        </ScrollBarContainer>
      </ContentsContainer>
    </Layout>
  );
};

export default DriverProgress;
