// This file was automatically generated and should not be edited.

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  File: {input: unknown; output: unknown};
  ISO8601DateTime: {input: string; output: string};
  JSON: {input: unknown; output: unknown};
};

export type AndroidConfig = {
  __typename?: 'AndroidConfig';
  build: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

export type AppConfig = {
  __typename?: 'AppConfig';
  android: AndroidConfig;
  ios: IosConfig;
};

/** Autogenerated input type of CreateDeliveryEvent */
export type CreateDeliveryEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the driver assignment the event will be associated with */
  driverAssignmentId: Scalars['ID']['input'];
  eventType: DmDeliveryEventTypes | `${DmDeliveryEventTypes}`;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of CreateDeliveryEvent. */
export type CreateDeliveryEventPayload = {
  __typename?: 'CreateDeliveryEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by field: driverAssignment. */
  dmDriverAssignment?: Maybe<DmDriverAssignment>;
  driverAssignment?: Maybe<DmDriverAssignment>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of CreateDriverImage */
export type CreateDriverImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  driverImage: DriverImageInput;
};

/** Autogenerated return type of CreateDriverImage. */
export type CreateDriverImagePayload = {
  __typename?: 'CreateDriverImagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  driverImage?: Maybe<DriverImage>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type DeliveryMetadata = {
  __typename?: 'DeliveryMetadata';
  /**
   * Whether or not this delivery is assignable to drivers
   * @deprecated This is being removed in favor of `self_assignable`
   */
  assignable: Scalars['Boolean']['output'];
  /** Whether or not this delivery is assignable to drivers */
  selfAssignable: Scalars['Boolean']['output'];
  /** Reason why delivery is not considered assignable */
  unassignableReason?: Maybe<SelfAssignErrorTypes | `${SelfAssignErrorTypes}`>;
};

/** Autogenerated input type of DestroyDriverSession */
export type DestroyDriverSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DestroyDriverSession. */
export type DestroyDriverSessionPayload = {
  __typename?: 'DestroyDriverSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Delivery address object */
export type DmAddress = {
  __typename?: 'DmAddress';
  /** City of the address */
  city?: Maybe<Scalars['String']['output']>;
  /** UUID of the address */
  id: Scalars['ID']['output'];
  /** Delivery instructions for this address */
  instructions?: Maybe<Scalars['String']['output']>;
  /** Short name for the address ie. ezCater Office */
  locationName?: Maybe<Scalars['String']['output']>;
  /** Contact phone number for address type */
  phone?: Maybe<Scalars['String']['output']>;
  /** Two-letter state abbreviation */
  state?: Maybe<Scalars['String']['output']>;
  /** Street1 of the address */
  street1?: Maybe<Scalars['String']['output']>;
  /** Optional street2 of the address */
  street2?: Maybe<Scalars['String']['output']>;
  /** Optional extended address */
  street3?: Maybe<Scalars['String']['output']>;
  /** Time zone used for the address */
  timeZoneIdentifier?: Maybe<Scalars['String']['output']>;
  /** Postal code of the address */
  zip?: Maybe<Scalars['String']['output']>;
};

/** Details of the ezCater delivery. */
export type DmDelivery = {
  __typename?: 'DmDelivery';
  /** Whether or not this delivery is assignable to drivers */
  assignable: Scalars['Boolean']['output'];
  cancelled: Scalars['Boolean']['output'];
  /** @deprecated Replaced by field: driverAskFor. */
  contactName?: Maybe<Scalars['String']['output']>;
  deliveryEvents: Array<DmDeliveryEvent>;
  /** Approximated distance between pickup and dropoff. */
  distanceInMiles?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Replaced by field: deliveryEvents. */
  dmDeliveryEvents?: Maybe<Array<DmDeliveryEvent>>;
  /** @deprecated Replaced by field: dropoffAddress. */
  dmDropoffAddress: DmAddress;
  /** @deprecated Replaced by field: order. */
  dmOrder: DmOrder;
  /** @deprecated Replaced by field: pickupAddress. */
  dmPickupAddress: DmAddress;
  driverAskFor?: Maybe<Scalars['String']['output']>;
  dropoffAddress: DmAddress;
  /** Approximated travel time between pickup and dropoff. */
  durationInMinutes?: Maybe<Scalars['Int']['output']>;
  eventAt: Scalars['ISO8601DateTime']['output'];
  /**
   * The event time adjusted with default 15 minute buffer.
   * @deprecated This timestamp is now represented by eventAtWithPadding
   */
  eventAtWithDefaultBuffer: Scalars['ISO8601DateTime']['output'];
  /** The event time adjusted with the default buffer if applicable. This does not include delivery partner padding for ezdispatch deliveries. */
  eventAtWithPadding: Scalars['ISO8601DateTime']['output'];
  /** @deprecated Replaced by field: ezDispatchDelivery.status. */
  ezDispatchStatus?: Maybe<DmEzDispatchStatus | `${DmEzDispatchStatus}`>;
  /** The order number (reference_id) formatted for standard ezCater display - #ABC-123 */
  formattedOrderNumber: Scalars['String']['output'];
  /** Whether or not in progress information exists for this delivery. */
  hasTrackingInformation: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isEzDispatch: Scalars['Boolean']['output'];
  /** @deprecated This is being deprecated without any alternative. It will always returns null because driver lateness in minutes are no longer recorded. */
  latenessInMinutes?: Maybe<Scalars['Int']['output']>;
  order: DmOrder;
  /** The UUID of the ezCater order. */
  orderId: Scalars['String']['output'];
  /** The unformatted ezCater order number. */
  orderNumber: Scalars['String']['output'];
  /** The UUID of the ezCater order. */
  orderUuid: Scalars['String']['output'];
  pickupAddress: DmAddress;
  /** The time for pickup via the delivery partner. */
  pickupAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * Pickup instructions for delivery drivers, provided by the caterer.
   * @deprecated Replaced by field: pickupAddress.instructions.
   */
  pickupInstructions?: Maybe<Scalars['String']['output']>;
  /** The priority of the delivery. */
  priority: DmDeliveryPriority | `${DmDeliveryPriority}`;
  /** @deprecated Replaced by field: orderNumber. */
  referenceId: Scalars['String']['output'];
  status?: Maybe<DmDeliveryStatus | `${DmDeliveryStatus}`>;
  /** Returns the most recent tracking delivery event */
  statusEvent?: Maybe<DmDeliveryEvent>;
  /** Fields that are missing information or contain validation errors. */
  validationErrors?: Maybe<Scalars['JSON']['output']>;
};

/** Events about the delivery created during the delivery lifecycle. */
export type DmDeliveryEvent = {
  __typename?: 'DmDeliveryEvent';
  /** ID of the associated Delivery. */
  deliveryId: Scalars['ID']['output'];
  driverAssignment?: Maybe<DmDriverAssignment>;
  eventType: DmDeliveryEventTypes | `${DmDeliveryEventTypes}`;
  id: Scalars['ID']['output'];
  /** The event creation source. */
  source: DmDeliveryEventSource | `${DmDeliveryEventSource}`;
  time: Scalars['ISO8601DateTime']['output'];
};

/** The delivery event creation source, whether directly or indirectly. */
export enum DmDeliveryEventSource {
  /** This is the default event source and any events that don't match other sources will be set to this. */
  DeliveryManagement = 'DELIVERY_MANAGEMENT',
  /** The event is directly from or a byproduct of a delivery partner. */
  DeliveryPartner = 'DELIVERY_PARTNER',
  /** The event is directly from or a byproduct of an ezDispatch process. */
  Ezdispatch = 'EZDISPATCH',
  /** The event was created from driver geolocation. */
  Geolocation = 'GEOLOCATION',
}

/** The event type for any given event */
export enum DmDeliveryEventTypes {
  /** The ezDispatch request has been accepted - either programatically or by a third party delivery partner */
  Accepted = 'accepted',
  /** ezDispatch has cancelled the assignment to a third party delivery partner */
  AssignmentCancelled = 'assignment_cancelled',
  /** ezDispatch has assigned the delivery to a third party delivery partner */
  AssignmentCreated = 'assignment_created',
  /** The delivery has been cancelled completely */
  Cancelled = 'cancelled',
  /** A delivery record has been created in either delivery management or ezDispatch */
  Created = 'created',
  /**
   * A customer has confirmed the food has been delivered
   * @deprecated This is being deprecated without any alternative. No delivery events have been created with this event type.
   */
  CustomerConfirmedDelivery = 'customer_confirmed_delivery',
  /** A driver has arrived at the dropoff location */
  DriverArrivedAtDropoff = 'driver_arrived_at_dropoff',
  /** A driver has arrived to pickup the food */
  DriverArrivedAtPickup = 'driver_arrived_at_pickup',
  /** A driver has been assigned - either by a catering partner or a third party delivery partner */
  DriverAssigned = 'driver_assigned',
  /** A driver has dropped of the food */
  DriverDroppedOff = 'driver_dropped_off',
  /** A driver is on the way to the customer */
  DriverEnRouteToDropoff = 'driver_en_route_to_dropoff',
  /** A driver is on the way to pickup the food */
  DriverEnRouteToPickup = 'driver_en_route_to_pickup',
  /** A driver has picked the food */
  DriverPickedUp = 'driver_picked_up',
  /** A driver has given feedback after completing a delivery */
  DriverProvidedFeedback = 'driver_provided_feedback',
  /**
   * A driver has reported that they are behind schedule
   * @deprecated This is being deprecated without any alternative. No delivery events have been created with this event type since 2022-02-09.
   */
  DriverReportedLateness = 'driver_reported_lateness',
  /** A gps location has been reported for a driver */
  DriverReportedLocation = 'driver_reported_location',
  /** A driver has been unassigned - either by a catering partner or third party delivery partner */
  DriverUnassigned = 'driver_unassigned',
  /** The delivery was not completed at least two hours after the scheduled event */
  Expired = 'expired',
  /** Some sort of issue has been reported with the delivery. */
  Issue = 'issue',
  /** Delivery related information has changed */
  Modified = 'modified',
  /** The delivery has been rejected by ezDispatch or an assignment has been rejected by a third party delivery partner */
  Rejected = 'rejected',
  /** ezDispatch request was deleted by the caterer. */
  RequestDeletedByCaterer = 'request_deleted_by_caterer',
  /** ezDispatch request was deleted by the customer. */
  RequestDeletedByCustomer = 'request_deleted_by_customer',
  /** ezDispatch request was deleted by the delivery partner. */
  RequestDeletedByDeliveryPartner = 'request_deleted_by_delivery_partner',
}

/** The priority of a delivery. */
export enum DmDeliveryPriority {
  /** A delivery that needs higher priority. */
  High = 'HIGH',
  /** A normal delivery priority. */
  Normal = 'NORMAL',
}

/** Current state - unassigned, assigned, in_progress, completed or expired - based on associated delivery events */
export enum DmDeliveryStatus {
  Assigned = 'assigned',
  Completed = 'completed',
  Expired = 'expired',
  InProgress = 'in_progress',
  Unassigned = 'unassigned',
}

/** Details for delivery drivers */
export type DmDriver = {
  __typename?: 'DmDriver';
  driverStores: Array<DmDriverStore>;
  /** @deprecated Replaced by field: driverStores[].ezcaterStoreId. */
  ezcaterStoreId: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  /** The driver's full name in the format of first then last name. */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  makeModel: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  smsOptInStatus?: Maybe<DmDriverOptInStatus | `${DmDriverOptInStatus}`>;
  /** @deprecated Replaced by field: makeModel. */
  vehicle?: Maybe<Scalars['JSON']['output']>;
};

export type DmDriverAssignment = {
  __typename?: 'DmDriverAssignment';
  delivery: DmDelivery;
  /** Latest delivery event type */
  deliveryStatus: DmDeliveryEventTypes | `${DmDeliveryEventTypes}`;
  /** @deprecated Replaced by field: delivery. */
  dmDelivery: DmDelivery;
  /** @deprecated Replaced by field: driver. */
  dmDriver: DmDriver;
  /** @deprecated Replaced by field: feedback. */
  dmFeedback?: Maybe<DmDriverFeedback>;
  driver: DmDriver;
  driverImages: Array<DriverImage>;
  feedback?: Maybe<DmDriverFeedback>;
  id: Scalars['ID']['output'];
  maskedPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use more strictly typed field delivery_status instead */
  status: Scalars['String']['output'];
};

export type DmDriverFeedback = {
  __typename?: 'DmDriverFeedback';
  dropoffLocation?: Maybe<Scalars['String']['output']>;
  liked: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

/** The vendor hosting the image. */
export enum DmDriverImageVendor {
  Filestack = 'FILESTACK',
}

/** Driver current SMS opt in status - no opt in decision, opted in, opted out */
export enum DmDriverOptInStatus {
  NoOptInDecision = 'NO_OPT_IN_DECISION',
  OptedIn = 'OPTED_IN',
  OptedOut = 'OPTED_OUT',
}

export type DmDriverStore = {
  __typename?: 'DmDriverStore';
  ezcaterStoreId: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
};

/** The current ezDispatch delivery request status. */
export enum DmEzDispatchStatus {
  /** ezDispatch delivery request was accepted. */
  Accepted = 'accepted',
  /** ezDispatch delivery request was cancelled due to order cancellation. */
  Cancelled = 'cancelled',
  /** ezDispatch delivery was requested, but the request was later deleted. */
  Deleted = 'deleted',
  /** ezDispatch delivery request was rejected. */
  Rejected = 'rejected',
  /** ezDispatch delivery request is pending. */
  Requested = 'requested',
}

export type DmOrder = {
  __typename?: 'DmOrder';
  ezcaterStoreId?: Maybe<Scalars['ID']['output']>;
  /** The number of items in the order. */
  itemCount: Scalars['Int']['output'];
  /** List of items with name/count on the order */
  orderItems: Array<DmOrderItem>;
  /** The ezCater order number. */
  orderNumber: Scalars['String']['output'];
  /** The subtotal value of the order in dollars. */
  subtotal: Scalars['Float']['output'];
  /** The subtotal value of the order in cents. */
  subtotalCents: Scalars['Int']['output'];
  /** The tip value in dollars. */
  tip: Scalars['Float']['output'];
  /** The tip value in cents. */
  tipCents: Scalars['Int']['output'];
};

export type DmOrderItem = {
  __typename?: 'DmOrderItem';
  /** Name for this item in the cart. */
  name: Scalars['String']['output'];
  /** Quantity for this item in the cart. */
  quantity: Scalars['Int']['output'];
};

/** Details for delivery drivers */
export type Driver = {
  __typename?: 'Driver';
  driverStores: Array<DmDriverStore>;
  /** @deprecated Replaced by field: driverStores[].ezcaterStoreId. */
  ezcaterStoreId: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  /** The driver's full name in the format of first then last name. */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  makeModel: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  smsOptInStatus?: Maybe<DmDriverOptInStatus | `${DmDriverOptInStatus}`>;
  /** @deprecated Replaced by field: makeModel. */
  vehicle?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of DriverAcceptSmsTerms */
export type DriverAcceptSmsTermsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DriverAcceptSmsTerms. */
export type DriverAcceptSmsTermsPayload = {
  __typename?: 'DriverAcceptSmsTermsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  driver?: Maybe<DmDriver>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of DriverAccountSelfDelete */
export type DriverAccountSelfDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DriverAccountSelfDelete. */
export type DriverAccountSelfDeletePayload = {
  __typename?: 'DriverAccountSelfDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  driver?: Maybe<DmDriver>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of DriverFeedback */
export type DriverFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the driver assignment */
  driverAssignmentId: Scalars['ID']['input'];
  dropoffLocation?: InputMaybe<Scalars['String']['input']>;
  liked?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DriverFeedback. */
export type DriverFeedbackPayload = {
  __typename?: 'DriverFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  feedback?: Maybe<DmDriverFeedback>;
};

export type DriverImage = {
  __typename?: 'DriverImage';
  driverAssignment: DmDriverAssignment;
  /** @deprecated Replaced by field: referenceId */
  filestackHandle?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by field: url */
  filestackUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  referenceId?: Maybe<Scalars['ID']['output']>;
  url: Scalars['String']['output'];
  /** The vendor hosting the image. */
  vendor?: Maybe<DmDriverImageVendor | `${DmDriverImageVendor}`>;
};

/** Details of driver photos that were uploaded in our driver apps */
export type DriverImageInput = {
  driverAssignmentId: Scalars['ID']['input'];
  filestackHandle: Scalars['String']['input'];
  filestackUrl: Scalars['String']['input'];
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type IosConfig = {
  __typename?: 'IosConfig';
  build: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Autogenerated input type of MagicLinkDriverSessionCreate */
export type MagicLinkDriverSessionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of MagicLinkDriverSessionCreate. */
export type MagicLinkDriverSessionCreatePayload = {
  __typename?: 'MagicLinkDriverSessionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Returns whether or not the request was successful */
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MagicLinkRequest */
export type MagicLinkRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryId?: InputMaybe<Scalars['ID']['input']>;
  driverAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  phone: Scalars['String']['input'];
  /** The source of where the driver assignment occured */
  source?: InputMaybe<SelfAssignSource | `${SelfAssignSource}`>;
};

/** Autogenerated return type of MagicLinkRequest. */
export type MagicLinkRequestPayload = {
  __typename?: 'MagicLinkRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  /** Returns whether or not the request was successful */
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MaskedPhoneNumber */
export type MaskedPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the driver assignment */
  driverAssignmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of MaskedPhoneNumber. */
export type MaskedPhoneNumberPayload = {
  __typename?: 'MaskedPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  driverAssignment?: Maybe<DmDriverAssignment>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createDeliveryEvent?: Maybe<CreateDeliveryEventPayload>;
  /** Create a Driver Image (from filestack) */
  createDriverImage?: Maybe<CreateDriverImagePayload>;
  /** Destroys a Driver Session */
  destroyDriverSession?: Maybe<DestroyDriverSessionPayload>;
  /** Accepts the SMS compliance terms for the driver. */
  driverAcceptSmsTerms?: Maybe<DriverAcceptSmsTermsPayload>;
  /** Soft deletes the drivers account of PII */
  driverAccountSelfDelete?: Maybe<DriverAccountSelfDeletePayload>;
  /** Creates a new Driver Feedback */
  driverFeedback?: Maybe<DriverFeedbackPayload>;
  /** Creates a magic link session + sets auth cookie given a token */
  magicLinkDriverSessionCreate?: Maybe<MagicLinkDriverSessionCreatePayload>;
  /** Allows a driver to request a magic link for login */
  magicLinkRequest?: Maybe<MagicLinkRequestPayload>;
  /** Creates or returns a masked phone number for a driver assignment */
  maskedPhoneNumber?: Maybe<MaskedPhoneNumberPayload>;
  /** Removes all assignments for a delivery */
  removeAssignments?: Maybe<RemoveAssignmentsPayload>;
  /** Allows the driver to request invitation by phone number */
  requestAccountCreate?: Maybe<RequestAccountCreatePayload>;
  /** Allows Driver to assign a delivery to themselves */
  selfAssignDriver?: Maybe<SelfAssignDriverPayload>;
  /** Create a Driver Image using the filestack API */
  uploadAssignmentImage?: Maybe<UploadAssignmentImagePayload>;
};

export type MutationCreateDeliveryEventArgs = {
  input: CreateDeliveryEventInput;
};

export type MutationCreateDriverImageArgs = {
  input: CreateDriverImageInput;
};

export type MutationDestroyDriverSessionArgs = {
  input: DestroyDriverSessionInput;
};

export type MutationDriverAcceptSmsTermsArgs = {
  input: DriverAcceptSmsTermsInput;
};

export type MutationDriverAccountSelfDeleteArgs = {
  input: DriverAccountSelfDeleteInput;
};

export type MutationDriverFeedbackArgs = {
  input: DriverFeedbackInput;
};

export type MutationMagicLinkDriverSessionCreateArgs = {
  input: MagicLinkDriverSessionCreateInput;
};

export type MutationMagicLinkRequestArgs = {
  input: MagicLinkRequestInput;
};

export type MutationMaskedPhoneNumberArgs = {
  input: MaskedPhoneNumberInput;
};

export type MutationRemoveAssignmentsArgs = {
  input: RemoveAssignmentsInput;
};

export type MutationRequestAccountCreateArgs = {
  input: RequestAccountCreateInput;
};

export type MutationSelfAssignDriverArgs = {
  input: SelfAssignDriverInput;
};

export type MutationUploadAssignmentImageArgs = {
  input: UploadAssignmentImageInput;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  appConfig: AppConfig;
  deliveryMetadata: DeliveryMetadata;
  dmDriverAssignment: DmDriverAssignment;
  /** The current delivery management driver identity */
  dmMe?: Maybe<Driver>;
  driverAssignments: Array<DmDriverAssignment>;
  driverImage: DriverImage;
  /** @deprecated This is retired in preparation for federated schema, use dmMe instead */
  me?: Maybe<Driver>;
};

export type QueryDeliveryMetadataArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDmDriverAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDriverAssignmentsArgs = {
  eventAtRange?: InputMaybe<TimeRangeInput>;
};

export type QueryDriverImageArgs = {
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of RemoveAssignments */
export type RemoveAssignmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the delivery to be unassigned */
  deliveryId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveAssignments. */
export type RemoveAssignmentsPayload = {
  __typename?: 'RemoveAssignmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deliveryId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of RequestAccountCreate */
export type RequestAccountCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryId?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

/** Autogenerated return type of RequestAccountCreate. */
export type RequestAccountCreatePayload = {
  __typename?: 'RequestAccountCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  phone?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SelfAssignDriver */
export type SelfAssignDriverInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the delivery to be assigned to */
  deliveryId: Scalars['ID']['input'];
  /** Driver provided first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Driver provided last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Origin of the request */
  source?: InputMaybe<SelfAssignSource | `${SelfAssignSource}`>;
};

/** Autogenerated return type of SelfAssignDriver. */
export type SelfAssignDriverPayload = {
  __typename?: 'SelfAssignDriverPayload';
  assignable?: Maybe<Scalars['Boolean']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deliveryId?: Maybe<Scalars['String']['output']>;
  driverAssignmentId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Reasons why a delivery may not be self assignabled */
export enum SelfAssignErrorTypes {
  /** Delivery is already assigned to another driver */
  Assigned = 'assigned',
  /** Delivery is now ezDispatch */
  Ezdispatch = 'ezdispatch',
  /** Delivery is now in a completed/expired/cancelled state */
  Finalized = 'finalized',
  /** Delivery is being handled by Relish */
  Relish = 'relish',
  /** Delivery is now takeout */
  Takeout = 'takeout',
}

/** Sources from where the driver initiated the self assign */
export enum SelfAssignSource {
  /** Via the flash message on Order Details */
  Alert = 'alert',
  /** Via the caterer accept flow */
  CatererAccept = 'caterer_accept',
  /** Via the food prep flow */
  FoodPrep = 'food_prep',
  /** Via the QR Code on the Order PDF */
  Qrcode = 'qrcode',
  /** Via an SMS with a link to sign up */
  Sms = 'sms',
}

/** Range of timestamps */
export type TimeRangeInput = {
  ending: Scalars['ISO8601DateTime']['input'];
  starting: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated input type of UploadAssignmentImage */
export type UploadAssignmentImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the driver assignment */
  driverAssignmentId: Scalars['ID']['input'];
  image: Scalars['File']['input'];
};

/** Autogenerated return type of UploadAssignmentImage. */
export type UploadAssignmentImagePayload = {
  __typename?: 'UploadAssignmentImagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  driverAssignment?: Maybe<DmDriverAssignment>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

export type CreateDeliveryEventMutationVariables = Exact<{
  input: CreateDeliveryEventInput;
}>;

export type CreateDeliveryEventMutation = {
  createDeliveryEvent?: {
    __typename?: 'CreateDeliveryEventPayload';
    errors?: Array<string> | null;
    driverAssignment?: {
      __typename?: 'DmDriverAssignment';
      id: string;
      deliveryStatus: DmDeliveryEventTypes;
    } | null;
  } | null;
};

export type DriverAccountSelfDeleteMutationVariables = Exact<{
  input: DriverAccountSelfDeleteInput;
}>;

export type DriverAccountSelfDeleteMutation = {
  driverAccountSelfDelete?: {
    __typename?: 'DriverAccountSelfDeletePayload';
    errors?: Array<string> | null;
    driver?: {__typename?: 'DmDriver'; id: string} | null;
  } | null;
};

export type DeliveryMetadataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeliveryMetadataQuery = {
  deliveryMetadata: {
    __typename?: 'DeliveryMetadata';
    assignable: boolean;
    unassignableReason?: SelfAssignErrorTypes | null;
  };
};

export type DestroyDriverSessionMutationVariables = Exact<{
  input: DestroyDriverSessionInput;
}>;

export type DestroyDriverSessionMutation = {
  destroyDriverSession?: {
    __typename?: 'DestroyDriverSessionPayload';
    clientMutationId?: string | null;
  } | null;
};

export type DriverAcceptSmsTermsMutationVariables = Exact<{
  input: DriverAcceptSmsTermsInput;
}>;

export type DriverAcceptSmsTermsMutation = {
  driverAcceptSmsTerms?: {
    __typename?: 'DriverAcceptSmsTermsPayload';
    errors?: Array<string> | null;
    driver?: {__typename?: 'DmDriver'; id: string} | null;
  } | null;
};

export type DriverAssignmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DriverAssignmentQuery = {
  driverAssignment: {
    __typename?: 'DmDriverAssignment';
    id: string;
    deliveryStatus: DmDeliveryEventTypes;
    delivery: {
      __typename?: 'DmDelivery';
      id: string;
      orderNumber: string;
      eventAtWithPadding: string;
      distanceInMiles?: number | null;
      durationInMinutes?: number | null;
      driverAskFor?: string | null;
      pickupAddress: {
        __typename?: 'DmAddress';
        id: string;
        instructions?: string | null;
        locationName?: string | null;
        street1?: string | null;
        street2?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
      };
      dropoffAddress: {
        __typename?: 'DmAddress';
        id: string;
        locationName?: string | null;
        street1?: string | null;
        street2?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        phone?: string | null;
        instructions?: string | null;
        timeZoneIdentifier?: string | null;
      };
      order: {
        __typename?: 'DmOrder';
        orderItems: Array<{__typename?: 'DmOrderItem'; name: string; quantity: number}>;
      };
    };
  };
};

export type DriverAssignmentsQueryVariables = Exact<{
  eventAtRange?: InputMaybe<TimeRangeInput>;
}>;

export type DriverAssignmentsQuery = {
  driverAssignments: Array<{
    __typename?: 'DmDriverAssignment';
    id: string;
    deliveryStatus: DmDeliveryEventTypes;
    delivery: {
      __typename?: 'DmDelivery';
      id: string;
      eventAtWithPadding: string;
      orderNumber: string;
      pickupAddress: {__typename?: 'DmAddress'; id: string; timeZoneIdentifier?: string | null};
      dropoffAddress: {
        __typename?: 'DmAddress';
        id: string;
        locationName?: string | null;
        street1?: string | null;
        street2?: string | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
      };
    };
  }>;
};

export type DriverFeedbackMutationVariables = Exact<{
  input: DriverFeedbackInput;
}>;

export type DriverFeedbackMutation = {
  driverFeedback?: {__typename?: 'DriverFeedbackPayload'; errors?: Array<string> | null} | null;
};

export type MagicLinkDriverSessionCreateMutationVariables = Exact<{
  input: MagicLinkDriverSessionCreateInput;
}>;

export type MagicLinkDriverSessionCreateMutation = {
  magicLinkDriverSessionCreate?: {
    __typename?: 'MagicLinkDriverSessionCreatePayload';
    success: boolean;
  } | null;
};

export type MaskedPhoneNumberMutationVariables = Exact<{
  input: MaskedPhoneNumberInput;
}>;

export type MaskedPhoneNumberMutation = {
  maskedPhoneNumber?: {
    __typename?: 'MaskedPhoneNumberPayload';
    errors?: Array<string> | null;
    driverAssignment?: {
      __typename?: 'DmDriverAssignment';
      id: string;
      deliveryStatus: DmDeliveryEventTypes;
      maskedPhoneNumber?: string | null;
    } | null;
  } | null;
};

export type MeQueryVariables = Exact<{[key: string]: never}>;

export type MeQuery = {
  me?: {
    __typename?: 'Driver';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    phone: string;
    smsOptInStatus?: DmDriverOptInStatus | null;
  } | null;
};

export type RemoveAssignmentsMutationVariables = Exact<{
  input: RemoveAssignmentsInput;
}>;

export type RemoveAssignmentsMutation = {
  removeAssignments?: {
    __typename?: 'RemoveAssignmentsPayload';
    deliveryId?: string | null;
    errors?: Array<{__typename?: 'Error'; code: number; message: string}> | null;
  } | null;
};

export type RequestAccountCreateMutationVariables = Exact<{
  input: RequestAccountCreateInput;
}>;

export type RequestAccountCreateMutation = {
  requestAccountCreate?: {__typename?: 'RequestAccountCreatePayload'; phone?: string | null} | null;
};

export type MagicLinkRequestMutationVariables = Exact<{
  input: MagicLinkRequestInput;
}>;

export type MagicLinkRequestMutation = {
  magicLinkRequest?: {
    __typename?: 'MagicLinkRequestPayload';
    success: boolean;
    errors?: Array<{__typename?: 'Error'; code: number; message: string}> | null;
  } | null;
};

export type SelfAssignDriverMutationVariables = Exact<{
  input: SelfAssignDriverInput;
}>;

export type SelfAssignDriverMutation = {
  selfAssignDriver?: {
    __typename?: 'SelfAssignDriverPayload';
    errors?: Array<string> | null;
    driverAssignmentId?: string | null;
  } | null;
};
