import {GraphQLError} from 'graphql';
import {ErrorHandler} from 'apollo-link-error';
import * as Sentry from '@sentry/browser'; // eslint-disable-line import/no-namespace

const captureException = (error: Error, extras: any) => {
  Sentry.withScope(scope => {
    scope.setExtra('errorInfo', extras);
    scope.setExtra('calledFrom', 'reportApolloError');
    Sentry.captureException(error);
  });
};

const getGraphqlErrorObjects = (errors?: ReadonlyArray<GraphQLError>) => {
  return (errors || [])
    .filter(err => err instanceof GraphQLError)
    .map(err => ({
      message: err.message,
      extensions: err.extensions,
      positions: err.positions,
      locations: err.locations,
      path: err.path,
    }));
};

type GetMessagesType = (args: {errors: ReadonlyArray<GraphQLError> | undefined}) => void;

const getMessages: GetMessagesType = ({errors}) => {
  const unknownErrorMessage = 'Unknown graphql error';

  try {
    const errorMessages: string[] = [];
    (errors || []).forEach(error => {
      const thisMessage = error.message || unknownErrorMessage;
      errorMessages.push(thisMessage);
    });

    if (errorMessages.length > 0) {
      const combinedErrorMessages = errorMessages.join('; ');
      return combinedErrorMessages;
    }

    return unknownErrorMessage;
  } catch (exception) {
    const extras = {
      errors: getGraphqlErrorObjects(errors),
    };
    captureException(exception, extras);
    return unknownErrorMessage;
  }
};

const reportApolloError: ErrorHandler = ({response, operation, networkError}) => {
  const errors = response && response.errors;

  if (networkError && !errors) {
    // monitoring using Datadog
    return;
  }

  const message = getMessages({errors});
  const extras = {
    errors: getGraphqlErrorObjects(errors),
    responseData: response?.data,
    query: operation?.query?.loc?.source?.body,
    variables: operation?.variables,
  };

  captureException(new Error(`Detected graphql error: ${message}`), extras);
};

export default reportApolloError;
