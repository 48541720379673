export const formatPhoneNumber = (str: string | null | undefined) => {
  const value = (str || '').replace(/[^\dx]/g, '');
  const match = value.match(/^(1|)?(\d{3})(\d{3})(\d{4})(x\d+)?$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    const extention = match[5] ? ` ${match[5]}` : '';
    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}${extention}`;
  }
  return value;
};

export const formatOrderNumber = (str: string | null | undefined) => {
  return (str || '').replace(/(\w{3})(\w{3})/, '#$1-$2');
};
