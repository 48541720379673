export const replaceUrlQueryParam = (
  url: Pick<URL, 'searchParams'> | URLSearchParams,
  queryParam: string | undefined | null,
  value: string
): Pick<URL, 'searchParams'> | URLSearchParams => {
  const searchParams = 'searchParams' in url ? url.searchParams : url;

  if (queryParam && searchParams.get(queryParam)) {
    searchParams.set(queryParam, value);
  }

  return url;
};

export const scrubUrlQueryParams = (
  url: Pick<URL, 'searchParams'> | URLSearchParams,
  queryParams: string[]
): Pick<URL, 'searchParams'> | URLSearchParams => {
  const searchParams = 'searchParams' in url ? url.searchParams : url;

  queryParams.forEach(queryParam => {
    replaceUrlQueryParam(searchParams, queryParam, 'FILTERED');
  });

  return url;
};
