import styled from '@emotion/styled';

export const CardContainer = styled.div`
  border: 1px solid ${({theme}) => theme.colors.border.base};
  border-radius: 5px;
  margin: ${({theme}) => theme.recipeSpacing.sm};
  background-color: ${({theme}) => theme.colors.white};

  & > * {
    padding: ${({theme}) => theme.recipeSpacing.md};
    border-bottom: 1px solid ${({theme}) => theme.colors.border.base};
  }

  & > h2 {
    padding: ${({theme}) => theme.recipeSpacing.xs} ${({theme}) => theme.recipeSpacing.md};
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background-color: ${({theme}) => theme.colors.page.background};
  }

  & > div:last-of-type {
    border-bottom: 0px solid ${({theme}) => theme.colors.border.base};
  }
`;
