export const TERMS_URL = 'https://www.ezcater.com/sms-terms';
export const COMPLETED_DELIVERIES_PATH = '/completed_deliveries';
export const DELIVERIES_PATH = '/deliveries';
export const EXPIRED_MAGIC_LINK_PATH = '/expired_magic_link';
export const FORGOT_PASSWORD_PATH = '/forgot_password';
export const HELP_PATH = '/help';
export const REQUEST_SIGN_IN_LINK_PATH = '/request_sign_in_link';
export const ORDER_HELP_PATH = '/help/:orderNumber';
export const PROGRESS_PATH = '/assignment/:driverAssignmentId';
export const SELF_ASSIGN_PATH = '/self_assign/:deliveryId';
export const SELF_ASSIGN_REGISTRATION_PATH = '/self_assign_registration/:deliveryId';

// Google URL Formatting
interface AddressType {
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

const googleBaseUrl = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&';

const googleFormattedAddress = (address: AddressType) => {
  const street2Value = address.street2 ? `+${address.street2}` : '';
  const urlParams = `${address.street1}${street2Value}+${address.zip}`;

  return urlParams.split(' ').join('+');
};

export const googleMapsUrl = (pickup: AddressType, dropoff: AddressType) =>
  `${googleBaseUrl}origin=${googleFormattedAddress(pickup)}
  &destination=${googleFormattedAddress(dropoff)}`;

export const googleMapsDestinationUrl = (dropoff: AddressType) =>
  `${googleBaseUrl}destination=${googleFormattedAddress(dropoff)}`;
