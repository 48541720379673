import {EzContent} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import {VFC} from 'react';

interface Props {
  readonly type: 'pickup' | 'dropoff';
  readonly driverAskFor?: string | null;
  readonly instructions?: string | null;
}

const ContactSection: VFC<Props> = ({type, driverAskFor, instructions}) => {
  const {t} = useTranslation();

  if (!driverAskFor && !instructions) {
    return null;
  }
  return (
    <>
      <EzContent>
        {driverAskFor && (
          <>
            <span style={{fontWeight: 700}}>{t('driverProgress.contactSection.driverAskFor')}</span>
            <br />
            <div style={{marginBottom: '1rem'}}>{driverAskFor}</div>
          </>
        )}
        <span style={{fontWeight: 700}}>
          {t(`driverProgress.contactSection.${type}Instructions`)}
        </span>
        <br />
        {instructions}
      </EzContent>
    </>
  );
};

export default ContactSection;
