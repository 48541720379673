import type {ComponentProps} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {ApolloProvider, apolloClient} from 'services/apollo';
import {GeneralError} from '@ezcater/ezmanage-common';
import {FlashMessagesProvider} from '@ezcater/flash-messages';
import FlashMessage from 'components/FlashMessage';
import useErrorBoundary from 'use-error-boundary';
import * as Sentry from '@sentry/browser'; // eslint-disable-line import/no-namespace
import {
  PROGRESS_PATH,
  HELP_PATH,
  ORDER_HELP_PATH,
  DELIVERIES_PATH,
  COMPLETED_DELIVERIES_PATH,
  EXPIRED_MAGIC_LINK_PATH,
  REQUEST_SIGN_IN_LINK_PATH,
  SELF_ASSIGN_PATH,
  SELF_ASSIGN_REGISTRATION_PATH,
} from 'paths';
import {
  DriverProgress,
  MyDeliveries,
  Help,
  CompletedDeliveries,
  SelfAssign,
  SelfAssignRegistration,
  ExpiredMagicLink,
  RequestSignInLink,
} from 'pages';
import CurrentAccountProviderWithQuery from 'components/Layout/CurrentAccountProviderWithQuery';

type AuthenticateRouteProps = ComponentProps<typeof Route> & {
  readonly redirect?: boolean;
};

const AuthenticatedRoute = ({redirect = true, ...props}: AuthenticateRouteProps) => {
  return (
    <CurrentAccountProviderWithQuery redirect={redirect}>
      <Route {...props} />
    </CurrentAccountProviderWithQuery>
  );
};

const Routes = () => {
  const {ErrorBoundary} = useErrorBoundary({
    onDidCatch: (error, errorInfo) => {
      Sentry.withScope(scope => {
        scope.setExtra('errorInfo', errorInfo);
        scope.setExtra('calledFrom', 'errorBoundary');
        Sentry.captureException(error);
      });
    },
  });

  return (
    <ErrorBoundary
      renderError={() => <GeneralError />}
      render={() => (
        <Router>
          <FlashMessagesProvider FlashMessageTemplate={FlashMessage}>
            <ApolloProvider client={apolloClient}>
              <Switch>
                <Route exact path={REQUEST_SIGN_IN_LINK_PATH} component={RequestSignInLink} />
                <AuthenticatedRoute
                  exact
                  path={[HELP_PATH, ORDER_HELP_PATH]}
                  component={Help}
                  redirect={false}
                />
                <Route exact path={EXPIRED_MAGIC_LINK_PATH} component={ExpiredMagicLink} />
                <AuthenticatedRoute
                  exact
                  path={SELF_ASSIGN_PATH}
                  component={SelfAssign}
                  redirect={false}
                />
                <AuthenticatedRoute
                  exact
                  path={SELF_ASSIGN_REGISTRATION_PATH}
                  component={SelfAssignRegistration}
                />
                <AuthenticatedRoute exact path={[DELIVERIES_PATH, '/']} component={MyDeliveries} />
                <AuthenticatedRoute
                  exact
                  path={COMPLETED_DELIVERIES_PATH}
                  component={CompletedDeliveries}
                />
                <AuthenticatedRoute exact path={PROGRESS_PATH} component={DriverProgress} />
              </Switch>
            </ApolloProvider>
          </FlashMessagesProvider>
        </Router>
      )}
    />
  );
};

export default Routes;
