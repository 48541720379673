import {useState} from 'react';
import {useQuery} from 'services/apollo';
import {EzPage, EzHeading, EzBanner} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import {endOfDay, startOfDay, addDays, isWithinInterval, formatISO} from 'date-fns';
import {DriverAssignments} from 'services/graphql';
import type {DriverAssignmentsQuery, DriverAssignmentsQueryVariables} from 'services/graphql/types';
import DeliveryCardContainer from 'components/DeliveryCardContainer';
import {FlashMessages} from '@ezcater/flash-messages';
import localStorageData from 'util/localStorageData';
import TrainingVideo from 'constants/TrainingVideo';
import Layout from '../Layout';

const MyDeliveries = () => {
  const {t} = useTranslation();
  const showGettingStartedStorage = localStorageData('showGettingStarted', true);
  const [showGettingStarted, setShowGettingStarted] = useState(showGettingStartedStorage.get());
  const hideGettingStarted = () => {
    setShowGettingStarted(showGettingStartedStorage.set(false));
  };

  const today = new Date();
  const inAMonth = addDays(today, 30);
  const eventAtRange = {
    starting: formatISO(startOfDay(today)),
    ending: formatISO(endOfDay(inAMonth)),
  };

  const {loading, data} = useQuery<DriverAssignmentsQuery, DriverAssignmentsQueryVariables>(
    DriverAssignments,
    {variables: {eventAtRange}}
  );

  const filterByDate = (start: Date, end: Date) =>
    data?.driverAssignments.filter(assignment =>
      isWithinInterval(new Date(assignment.delivery.eventAtWithPadding), {
        start: startOfDay(start),
        end: endOfDay(end),
      })
    );

  const todaysAssignments = {driverAssignments: filterByDate(today, today) || []};
  const upcomingAssignments = {driverAssignments: filterByDate(addDays(today, 1), inAMonth) || []};

  const title = t('myDeliveries.title');
  const header = t('myDeliveries.header');
  const todayTableTitle = t('myDeliveries.tableTitleToday');
  const upcomingTableTitle = t('myDeliveries.tableTitleUpcoming');
  const gettingStartedTitle = t('myDeliveries.gettingStarted.title');
  const gettingStartedMessage = t('myDeliveries.gettingStarted.message');
  const gettingStartedLink = t('myDeliveries.gettingStarted.link');

  const gettingStartedBanner = showGettingStarted ? (
    <div data-testid="gs-banner">
      <EzBanner
        title={gettingStartedTitle}
        message={gettingStartedMessage}
        link={{
          label: gettingStartedLink,
          onClick: () => {
            window.open(TrainingVideo.url, TrainingVideo.target);
          },
        }}
        use="ezOrdering"
        onDismiss={hideGettingStarted}
      />
    </div>
  ) : (
    false
  );

  return (
    <Layout title={title}>
      <EzPage>
        {gettingStartedBanner}
        <FlashMessages />
        <EzHeading size="1">{header}</EzHeading>
        <DeliveryCardContainer
          data={todaysAssignments}
          loading={loading}
          header={todayTableTitle}
        />
        <DeliveryCardContainer
          data={upcomingAssignments}
          loading={loading}
          header={upcomingTableTitle}
        />
      </EzPage>
    </Layout>
  );
};

export default MyDeliveries;
