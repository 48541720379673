import {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useQuery, useMutation} from 'services/apollo';
import {EzPage} from '@ezcater/recipe';
import LoadingSpinner from 'components/LoadingSpinner';
import {SelfAssignDriver, DeliveryMetadata} from 'services/graphql';
import {SelfAssignErrorTypes} from 'services/graphql/types';
import type {
  DeliveryMetadataQuery,
  DeliveryMetadataQueryVariables,
  SelfAssignDriverMutation,
  SelfAssignDriverMutationVariables,
  SelfAssignSource,
} from 'services/graphql/types';
import {useTranslation} from 'react-i18next';
import {useCurrentAccount} from 'components/CurrentAccount';
import SelfAssignError from 'components/SelfAssignError';
import {REQUEST_SIGN_IN_LINK_PATH} from 'paths';
import queryString from 'query-string';
import {SELF_ASSIGN_PAGE_NAME} from 'util/tracking/trackingEvents';
import {trackPageView} from 'util/tracking/trackEvent';
import Layout from '../Layout';

interface RouteParams {
  deliveryId: string;
}

interface NameStateProps {
  firstName?: string;
  lastName?: string;
}

const SelfAssign = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {deliveryId} = useParams<RouteParams>();
  const {search, state: locationState} = useLocation();
  const source = queryString.parse(search).source as SelfAssignSource | undefined;
  const fullName = locationState as NameStateProps;

  const [hasErrors, setHasErrors] = useState(false);

  const {id} = useCurrentAccount();
  const isLoggedIn = !!id;

  // Fire funnel analytics on load
  useEffect(() => {
    trackPageView(SELF_ASSIGN_PAGE_NAME, {
      driver_id: id,
      delivery_id: deliveryId,
    });
  }, [deliveryId, id]);

  const [selfAssign, {loading: selfAssignLoading, data: selfAssignData}] = useMutation<
    SelfAssignDriverMutation,
    SelfAssignDriverMutationVariables
  >(SelfAssignDriver, {
    onError: () => {
      setHasErrors(true);
    },
  });

  const {loading, data: assignableData} = useQuery<
    DeliveryMetadataQuery,
    DeliveryMetadataQueryVariables
  >(DeliveryMetadata, {
    variables: {id: deliveryId},
    onError: () => {
      setHasErrors(true);
    },
  });

  useEffect(() => {
    // When a driver is logged in we want to call SelfAssignDriver
    // which will decide if the driver is already assigned or render
    // the error view based on response
    if (isLoggedIn) {
      selfAssign({
        variables: {
          input: {
            deliveryId,
            source,
            ...(fullName || {}),
          },
        },
      });
    }
  }, [deliveryId, fullName, isLoggedIn, selfAssign, source]);

  useEffect(() => {
    if (selfAssignData?.selfAssignDriver?.driverAssignmentId) {
      history.push(`/assignment/${selfAssignData.selfAssignDriver.driverAssignmentId}`);
    }

    if (selfAssignData?.selfAssignDriver?.errors) {
      setHasErrors(true);
    }
  }, [history, selfAssignData]);

  // If someone bypasses DriverAuthEnabledRedirect by removing the `source` parameter,
  // redirect and assume source is qrCode so they can request a new sign in link
  useEffect(() => {
    if (!isLoggedIn) {
      history.push(`${REQUEST_SIGN_IN_LINK_PATH}?deliveryId=${deliveryId}&source=qrcode`);
    }
  }, [isLoggedIn, history, source, deliveryId]);

  const title = t('selfAssign.title');

  return loading || selfAssignLoading ? (
    <LoadingSpinner />
  ) : (
    <Layout title={title}>
      <EzPage>
        {((isLoggedIn && !assignableData?.deliveryMetadata?.assignable) || hasErrors) && (
          <SelfAssignError
            message={assignableData?.deliveryMetadata?.unassignableReason || ''}
            showOverrideOptions={
              assignableData?.deliveryMetadata?.unassignableReason === SelfAssignErrorTypes.Assigned
            }
            deliveryId={deliveryId}
            source={source}
            handleSelfAssign={selfAssign}
          />
        )}
      </EzPage>
    </Layout>
  );
};

export default SelfAssign;
