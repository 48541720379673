import {FormEvent, useState} from 'react';
import {useMutation} from 'services/apollo';
import {EzPage, EzCard, EzHeading, EzField, EzLink} from '@ezcater/recipe';
import {FlashMessages} from '@ezcater/flash-messages';

import {RequestMagicLink, RequestAccountCreate} from 'services/graphql';
import {formatPhoneNumber} from 'util/StringUtils';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {useHistory, RouteComponentProps} from 'react-router-dom';
import {usePageViewTracking} from 'util/tracking';
import {REQUEST_SIGN_IN_LINK} from 'util/tracking/trackingEvents';
import {SelfAssignSource} from 'services/graphql/types';
import type {
  MagicLinkRequestMutation,
  MagicLinkRequestMutationVariables,
  RequestAccountCreateMutation,
  RequestAccountCreateMutationVariables,
} from 'services/graphql/types';
import Button from 'components/Button';
import {TERMS_URL} from 'paths';
import Layout from '../Layout';
import RequestSignInLinkSuccess from './RequestSignInLinkSuccess';

const getUrlParams = (
  location: RouteComponentProps['location']
): {deliveryId?: string; source?: `${SelfAssignSource}`} => {
  const parsedQueryString = queryString.parse(location.search);

  return {
    deliveryId: parsedQueryString.deliveryId as string | undefined,
    source: parsedQueryString.source as SelfAssignSource | undefined,
  };
};

const RequestSignInLink = () => {
  usePageViewTracking(REQUEST_SIGN_IN_LINK);
  const history = useHistory();
  const {t} = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');

  const {deliveryId, source} = getUrlParams(history.location);

  const onChange = (value: string) => {
    setPhoneNumber(value);
  };

  const [requestInvitationQuery, requestInvitationData] = useMutation<
    RequestAccountCreateMutation,
    RequestAccountCreateMutationVariables
  >(RequestAccountCreate);

  const [magicLinkRequestQuery, magicLinkRequestData] = useMutation<
    MagicLinkRequestMutation,
    MagicLinkRequestMutationVariables
  >(RequestMagicLink);

  const requestInvitation = async () => {
    await requestInvitationQuery({
      variables: {
        input: {
          phone: phoneNumber,
          deliveryId,
        },
      },
    });
  };

  const requestMagicLink = async () => {
    await magicLinkRequestQuery({
      variables: {
        input: {
          phone: phoneNumber,
          deliveryId,
          source,
        },
      },
    });
  };

  const isQrCodeSelfAssign = !!(source && source === SelfAssignSource.Qrcode && deliveryId);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (isQrCodeSelfAssign) {
        await requestInvitation();
      } else {
        await requestMagicLink();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defaultError = t('requestSignInLink.error');

  const getError = () => {
    if (isQrCodeSelfAssign) {
      if (!requestInvitationData.loading && requestInvitationData.error) {
        return defaultError;
      }

      return null;
    }

    if (!magicLinkRequestData.loading) {
      if (magicLinkRequestData.error) {
        return defaultError;
      }

      if (magicLinkRequestData?.data?.magicLinkRequest?.errors?.length) {
        return magicLinkRequestData?.data?.magicLinkRequest?.errors[0].message;
      }
    }
  };

  const title = t('requestSignInLink.title');
  const header = t('requestSignInLink.header');
  const subheader = t('requestSignInLink.subheader');
  const phoneNumberText = t('requestSignInLink.phoneNumber');
  const phoneNumberPlaceholder = t('requestSignInLink.phoneNumberPlaceholder');
  const buttonText = t('requestSignInLink.button');
  const disclaimer = t('requestSignInLink.disclaimer');
  const terms = t('requestSignInLink.terms');

  const error = getError();

  const requestComplete =
    (!magicLinkRequestData.loading && magicLinkRequestData.data?.magicLinkRequest?.success) ||
    (!requestInvitationData.loading && requestInvitationData.data?.requestAccountCreate?.phone);

  const showSuccessMessages = requestComplete && !error;

  return (
    <Layout title={title}>
      <EzPage>
        <FlashMessages />
        {showSuccessMessages && <RequestSignInLinkSuccess />}
        {!showSuccessMessages && (
          <>
            <EzHeading size={'3'}>{header}</EzHeading>
            <EzHeading size={'4'}>{subheader}</EzHeading>
            <form onSubmit={handleSubmit}>
              <EzCard>
                {error && <>{error}</>}
                <>
                  <EzField
                    type="text"
                    label={phoneNumberText}
                    value={formatPhoneNumber(phoneNumber)}
                    onChange={e => onChange(e.currentTarget.value)}
                    placeholder={phoneNumberPlaceholder}
                  />
                  <Button
                    type="submit"
                    buttonType="primary"
                    buttonText={buttonText}
                    loading={magicLinkRequestData.loading}
                    disabled={phoneNumber === ''}
                  />
                  <EzHeading size="6">
                    {disclaimer}&nbsp;&nbsp;
                    <EzLink href={TERMS_URL} target="_blank">
                      {terms}
                    </EzLink>
                  </EzHeading>
                </>
              </EzCard>
            </form>
          </>
        )}
      </EzPage>
    </Layout>
  );
};

export default RequestSignInLink;
