import {ReactNode} from 'react';
import CurrentAccountContext from './CurrentAccountContext';

type CurrentAccountProviderProps = {
  id: string | null;
  children: ReactNode;
};

const CurrentAccountProvider = ({id, children}: CurrentAccountProviderProps) => (
  <CurrentAccountContext.Provider value={{id}}>{children}</CurrentAccountContext.Provider>
);

export default CurrentAccountProvider;
