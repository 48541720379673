import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {EzPage, EzCard, EzHeading, EzField, EzLink} from '@ezcater/recipe';
import {FlashMessages, useFlashMessages} from '@ezcater/flash-messages';
import Button from 'components/Button';
import NotFound from 'components/NotFound';
import {useTranslation} from 'react-i18next';
import {usePageViewTracking} from 'util/tracking';
import {SELF_ASSIGN_REGISTRATION} from 'util/tracking/trackingEvents';
import {TERMS_URL} from 'paths';
import {useMutation} from 'services/apollo';
import {SelfAssignDriver} from 'services/graphql';
import type {
  SelfAssignDriverMutation,
  SelfAssignDriverMutationVariables,
  SelfAssignSource,
} from 'services/graphql/types';
import Layout from '../Layout';

interface RouteParams {
  deliveryId: string;
}

interface FieldProps {
  touched: boolean;
  error: string;
  value: string;
}

interface NameStateProps {
  firstName: FieldProps;
  lastName: FieldProps;
}

const SelfAssignRegistration = () => {
  usePageViewTracking(SELF_ASSIGN_REGISTRATION);
  const {t} = useTranslation();
  const {addFlashMessage} = useFlashMessages();
  const {deliveryId} = useParams<RouteParams>();
  const history = useHistory();
  const {search} = history.location;
  const source = new URLSearchParams(search).get('source') as SelfAssignSource | undefined;
  const defaultFieldProps: FieldProps = {touched: false, error: '', value: ''};
  const [nameProps, setNameProps] = useState<NameStateProps>({
    firstName: defaultFieldProps,
    lastName: defaultFieldProps,
  });
  const [formErrors, setFormErrors] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  const fullName = {
    firstName: nameProps.firstName.value,
    lastName: nameProps.lastName.value,
  };

  const title = t('selfAssignRegistration.title');
  const header = t('selfAssignRegistration.header');
  const subheader = t('selfAssignRegistration.subheader');
  const firstNameLabel = t('selfAssignRegistration.firstNameLabel');
  const lastNameLabel = t('selfAssignRegistration.lastNameLabel');
  const firstNamePlaceholder = t('selfAssignRegistration.firstNamePlaceholder');
  const lastNamePlaceholder = t('selfAssignRegistration.lastNamePlaceholder');
  const buttonText = t('selfAssignRegistration.button');
  const disclaimer = t('selfAssignRegistration.disclaimer');
  const terms = t('selfAssignRegistration.terms');
  const errorText = t('selfAssignRegistration.error');

  const [selfAssign, {loading: selfAssignLoading, error: selfAssignError}] = useMutation<
    SelfAssignDriverMutation,
    SelfAssignDriverMutationVariables
  >(SelfAssignDriver, {
    onError: () => {
      setHasErrors(true);
    },
  });

  if ((!selfAssignLoading && selfAssignError) || hasErrors) {
    return <NotFound />;
  }

  const showFlashMessage = () => {
    addFlashMessage({
      type: 'error',
      message: errorText,
      onRedirect: true,
    });
  };

  const onNameChange = (field: string, value: string) => {
    const newProps = {
      ...nameProps,
      [field]: {
        error: value === '' && 'Please complete this field.',
        touched: true,
        value,
      },
    };
    setNameProps(newProps);

    const allValues = [...Object.values(newProps)];
    setFormErrors(!!allValues.find(key => key.touched === false || key.value === ''));
  };

  const handleSubmit = async () => {
    if (formErrors) {
      return;
    }

    const response = await selfAssign({
      variables: {
        input: {
          deliveryId,
          source,
          ...fullName,
        },
      },
    });

    if (response?.errors) {
      setHasErrors(true);
    }

    if (response?.data?.selfAssignDriver?.errors) {
      showFlashMessage();
      history.push(`/self_assign_registration/${deliveryId}${search}`);
    } else if (response?.data?.selfAssignDriver?.driverAssignmentId) {
      history.push(`/assignment/${response.data.selfAssignDriver.driverAssignmentId}`);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Layout title={title}>
        <EzPage>
          <EzHeading size={'1'}>{header}</EzHeading>
          <FlashMessages />
          <EzCard>
            <EzHeading size={'4'}>{subheader}</EzHeading>
            <EzField
              type="text"
              label={firstNameLabel}
              value={nameProps.firstName.value}
              touched={nameProps.firstName.touched}
              error={nameProps.firstName.error}
              onChange={e => onNameChange('firstName', e.currentTarget.value)}
              placeholder={firstNamePlaceholder}
            />
            <EzField
              type="text"
              label={lastNameLabel}
              value={nameProps.lastName.value}
              touched={nameProps.lastName.touched}
              error={nameProps.lastName.error}
              onChange={e => onNameChange('lastName', e.currentTarget.value)}
              placeholder={lastNamePlaceholder}
            />
            <Button
              buttonType="primary"
              buttonText={buttonText}
              type="submit"
              onClick={handleSubmit}
              loading={false}
              disabled={selfAssignLoading || formErrors}
            />
            <EzHeading size="6">
              {disclaimer}&nbsp;&nbsp;
              <EzLink href={TERMS_URL} target="_blank">
                {terms}
              </EzLink>
            </EzHeading>
          </EzCard>
        </EzPage>
      </Layout>
    </form>
  );
};

export default SelfAssignRegistration;
