import styled from '@emotion/styled';

export const CalendarCard = styled.div`
  border: 1px solid ${({theme}) => theme.colors.border.base};
  border-top: 8px solid ${({theme}) => theme.colors.border.base};
  border-radius: 5px;
  padding: 10px 0px;
  background-color: ${({theme}) => theme.colors.page.background};
  width: 100px;
  text-align: center;
`;

export const Month = styled.div`
  font-weight: ${({theme}) => theme.fontWeights.bold};
  font-size: ${({theme}) => theme.fontSizes[500]};
`;

export const DayOfMonth = styled.div`
  font-weight: 500;
`;

export const OrderNumber = styled.div`
  font-weight: ${({theme}) => theme.fontWeights.bold};
  font-size: ${({theme}) => theme.fontSizes[500]};
  margin-bottom: ${({theme}) => theme.recipeSpacing.xs2};
  ${({theme}) => `color: ${theme.colors.interactive.base};`}
`;

export const RightSide = styled.div`
  text-align: right;
`;

export const CardLink = styled.div`
  cursor: pointer;
`;
