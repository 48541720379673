import type {ComponentProps} from 'react';
import {EzNavigation} from '@ezcater/recipe';
import {NavLink, useHistory} from 'react-router-dom';
import {useCurrentAccount} from 'components/CurrentAccount';
import {useMutation} from 'services/apollo';
import DestroyDriverSession from 'services/graphql/DestroyDriverSession';
import {useFlashMessages} from '@ezcater/flash-messages';
import type {
  DestroyDriverSessionMutation,
  DestroyDriverSessionMutationVariables,
} from 'services/graphql/types';
import {REQUEST_SIGN_IN_LINK_PATH} from 'paths';
import {useCurrentAssignment} from '../CurrentAssignment';

interface HeaderProps {
  children: React.ReactNode;
}

const Header = ({children}: HeaderProps) => {
  const {orderNumber} = useCurrentAssignment();
  const history = useHistory();

  const handleError = () => {
    addFlashMessage({
      type: 'error',
      message: 'There was an error logging you out.  Please try again.',
    });
  };

  const [signOut] = useMutation<
    DestroyDriverSessionMutation,
    DestroyDriverSessionMutationVariables
  >(DestroyDriverSession, {variables: {input: {}}, onError: handleError});
  const {addFlashMessage} = useFlashMessages();
  const helpLink = orderNumber ? `/help/${orderNumber.replace(/[^0-9a-zA-Z]/g, '')}` : '/help';
  const {id} = useCurrentAccount();
  const signOutClick = async () => {
    const response = await signOut();
    if (response?.data?.destroyDriverSession) {
      history.push(REQUEST_SIGN_IN_LINK_PATH);
    }
  };

  const helpElement = {to: helpLink, label: 'Help', as: NavLink};
  const getNavLinks = (): ComponentProps<typeof EzNavigation>['links'] => {
    if (id) {
      return [
        {to: '/deliveries', label: 'My Deliveries', as: NavLink},
        {to: helpLink, label: 'Help', as: NavLink},
        {href: '#', label: 'Sign Out', onClick: signOutClick},
      ];
    }

    return [helpElement, {to: REQUEST_SIGN_IN_LINK_PATH, label: 'Sign In', as: NavLink}];
  };

  return (
    <EzNavigation
      home={{
        to: '#', // TODO: Change to deliveries page
        label: 'ezCater',
        logo: {src: '/static/images/brand/logo-light.png', width: 100},
        as: NavLink,
      }}
      links={getNavLinks()}
    >
      {children}
    </EzNavigation>
  );
};

export default Header;
