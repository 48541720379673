import {ComponentPropsWithRef, forwardRef} from 'react';

type Props = ComponentPropsWithRef<'svg'>;

const LocationArrowIcon = forwardRef<SVGSVGElement, Props>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props} ref={ref}>
      <path d="M461.9 0c-5.73 0-11.59 1.1-17.39 3.52L28.74 195.41c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 30.01 24.21 47.93 48.74 47.93 17.3 0 34.75-8.9 44.01-28.74l191.9-415.78C522.06 34.89 494.14 0 461.9 0zM271.81 464.07V240.19h-47.97l-175.48.71c-.27-.37-.47-1.35.48-1.93L462.05 48.26c.61.41 1.28 1.07 1.69 1.68L271.81 464.07z" />
    </svg>
  );
});

LocationArrowIcon.displayName = 'LocationArrow';

export default LocationArrowIcon;
