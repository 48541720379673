import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import {setContext} from 'apollo-link-context';
import {HttpLink} from 'apollo-link-http';
import Environment from '@ezcater/env-vars';
import reportApolloError from './reportApolloError';

const client = (uri: string) => {
  const httpLink = new HttpLink({
    uri,
    credentials: 'include',
  });
  const headersLink = setContext((_request, {headers = {}}) => ({
    headers: {
      ...headers,
      'X-EZCATER-SERVICE-NAME': 'delivery-management-react',
      'X-EZCATER-SOURCE': 'client',
    },
  }));
  const errorLink = onError(reportApolloError);

  return new ApolloClient({
    link: ApolloLink.from([errorLink, headersLink, httpLink]),
    cache: new InMemoryCache(),
    name: 'delivery-management-react',
    connectToDevTools: Environment.fetch('NODE_ENV', 'production') === 'development',
  });
};

export default client(`${Environment.fetch('DELIVERY_MANAGEMENT_HOST')}/graphql`);
