import {forwardRef} from 'react';
import type {ComponentProps} from 'react';
import {EzButton} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import {isAfter, parseISO, isValid, subMinutes} from 'date-fns';

const EVENT_AT_OFFSET_MINUTES = 90;

// Check if the current `eventAt` time is before 2 hours
const isDisabled = (eventAt: string) => {
  const parsedEventAt = parseISO(eventAt);

  if (!isValid(parsedEventAt)) {
    return false;
  }

  // Delivery can begin two hours before the event
  const minStartTime = subMinutes(parsedEventAt, EVENT_AT_OFFSET_MINUTES);

  return isAfter(minStartTime, new Date());
};

type ButtonProps = Partial<ComponentProps<typeof EzButton>>;

type Props = ButtonProps & {
  loading: boolean | undefined;
  eventAt: string;
};

const StartDeliveryButton = forwardRef<HTMLButtonElement, Props>(
  ({loading, eventAt, ...props}, ref) => {
    const {t} = useTranslation();
    const disabled = loading || isDisabled(eventAt);
    const buttonLabel = t(
      loading
        ? 'driverProgress.startDelivery.submittingButtonLabel'
        : 'driverProgress.startDelivery.buttonLabel'
    );
    return (
      <EzButton {...props} disabled={disabled} use="primary" ref={ref} style={{padding: '1rem'}}>
        {buttonLabel}
      </EzButton>
    );
  }
);

StartDeliveryButton.displayName = 'StartDelivery';

export default StartDeliveryButton;
