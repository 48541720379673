import {VFC} from 'react';
import {EzHeading, EzLayout, EzLink} from '@ezcater/recipe';
import {googleMapsDestinationUrl} from 'paths';
import formatAddress from 'services/addressFormatter';
import LocationArrowIcon from './LocationArrowIcon';

interface Props {
  readonly address?:
    | (Parameters<typeof googleMapsDestinationUrl>[0] & Parameters<typeof formatAddress>[0])
    | null;
  /**
   * If disabled, this link will not be clickable
   */
  readonly disabled?: boolean;
}

const MapLink: VFC<Props> = ({disabled = false, address}) => {
  if (!address) {
    return null;
  }

  if (disabled) {
    return (
      <EzHeading color="blue" size="4">
        {formatAddress(address)}
      </EzHeading>
    );
  }

  return (
    <EzLayout layout="basic">
      <LocationArrowIcon height="16px" width="16px" data-testid="navigation-icon" />
      <EzLink
        use="secondary"
        rel="noopener noreferrer"
        href={googleMapsDestinationUrl(address)}
        target="_blank"
      >
        {formatAddress(address)}
      </EzLink>
    </EzLayout>
  );
};

export default MapLink;
