import gql from 'graphql-tag';

const RemoveAssignments = gql(/* GraphQL */ `
  mutation RemoveAssignments($input: RemoveAssignmentsInput!) {
    removeAssignments(input: $input) {
      errors {
        code
        message
      }
      deliveryId
    }
  }
`);

export default RemoveAssignments;
