import type {ReactNode} from 'react';
import Header from 'components/Header';
import PhoneNumbers from 'constants/PhoneNumbers';
import Footer from 'components/Footer';
import {CurrentAssignmentProvider} from 'components/CurrentAssignment';
import Helmet from 'components/Helmet';
import LoadingSpinner from 'components/LoadingSpinner';
import {FeatureFlagsProvider, createFeatureFlagsClient} from '@ezcater/feature-flags-react';
import Environment from '@ezcater/env-vars';

interface LayoutProps {
  children: ReactNode;
  title: string;
  loading?: boolean;
  driverAssignmentId?: string;
  driverId?: string;
  deliveryId?: string;
  orderNumber?: string;
}

const Layout = ({
  children,
  title,
  loading,
  driverAssignmentId,
  deliveryId,
  driverId,
  orderNumber,
}: LayoutProps) => {
  const client = createFeatureFlagsClient({
    host: Environment.fetch('FEATURE_FLAGS_HOST'),
    environment: Environment.fetch('NODE_ENV', ''),
  });
  return (
    <>
      <Helmet title={title}>
        <meta name="apple-itunes-app" content="app-id=1551189516" />
        <meta name="google-play-app" content="app-id=com.ezcater.delivery" />
        <link rel="apple-touch-icon" href="/static/images/app-icon.png" />
        <link rel="android-touch-icon" href="/static/images/app-icon.png" />
      </Helmet>
      {loading && <LoadingSpinner />}
      {!loading && (
        <CurrentAssignmentProvider
          id={driverAssignmentId}
          driverId={driverId}
          deliveryId={deliveryId}
          orderNumber={orderNumber}
        >
          <FeatureFlagsProvider trackingId={driverId} client={client}>
            <Header>{children}</Header>
            <Footer phone={PhoneNumbers.EZDISPATCH_DIRECT} />
          </FeatureFlagsProvider>
        </CurrentAssignmentProvider>
      )}
    </>
  );
};

export default Layout;
