import {useQuery} from 'services/apollo';
import {EzPage, EzHeading} from '@ezcater/recipe';
import {useTranslation} from 'react-i18next';
import DriverAssignments from 'services/graphql/DriverAssignments';
import type {DriverAssignmentsQuery, DriverAssignmentsQueryVariables} from 'services/graphql/types';
import DeliveryCardContainer from 'components/DeliveryCardContainer';
import {addDays, startOfToday} from 'date-fns';
import Layout from '../Layout';

const CompletedDeliveries = () => {
  const {t} = useTranslation();
  const {loading, data} = useQuery<DriverAssignmentsQuery, DriverAssignmentsQueryVariables>(
    DriverAssignments,
    {
      variables: {
        eventAtRange: {
          starting: addDays(startOfToday(), -30).toISOString(),
          ending: startOfToday().toISOString(),
        },
      },
    }
  );

  const title = t('completedDeliveries.title');
  const header = t('completedDeliveries.header');
  const tableTitle = t('completedDeliveries.tableTitle');

  return (
    <Layout title={title}>
      <EzPage>
        <EzHeading size={'1'}>{header}</EzHeading>
        <DeliveryCardContainer data={data} loading={loading} header={tableTitle} />
      </EzPage>
    </Layout>
  );
};

export default CompletedDeliveries;
