import {useState} from 'react';
import {EzButton, EzPage, EzCard, EzHeading, EzLink, EzTextStyle} from '@ezcater/recipe';
import {FlashMessages} from '@ezcater/flash-messages';
import {useParams} from 'react-router-dom';
import {formatOrderNumber} from 'util/StringUtils';
import {useTranslation} from 'react-i18next';

import {useCurrentAccount} from 'components/CurrentAccount';
import PhoneNumbers from 'constants/PhoneNumbers';
import TrainingVideo from 'constants/TrainingVideo';
import {trackButtonClick} from 'util/tracking';
import {DRIVER_HELP_CALL_LINK, DRIVER_HELP_EMAIL_LINK} from 'util/tracking/trackingEvents';
import {InfoTextWrapper} from './Help.styles';
import Layout from '../Layout';
import AccountDeletionModal from './AccountDeletionModal';

interface RouteParams {
  orderNumber: string;
}

const Help = () => {
  const currentAccount = useCurrentAccount();

  const {t} = useTranslation();
  const {orderNumber} = useParams<RouteParams>();
  const [accountDeletionModalOpen, setAccountDeletionModalOpen] = useState(false);

  const handleModalDismiss = () => {
    setAccountDeletionModalOpen(false);
  };

  const orderText = t('help.order');
  return (
    <Layout title={t('help.title')}>
      <EzPage>
        <FlashMessages />
        <EzHeading size={'1'}>{t('help.header')}</EzHeading>
        <EzCard>
          <p>
            {t('help.urgent1')}&nbsp;
            <EzLink
              onClick={() =>
                trackButtonClick(DRIVER_HELP_CALL_LINK, {
                  driver_id: currentAccount?.id || null,
                  misc_json: JSON.stringify({
                    order_number: orderNumber || null,
                  }),
                })
              }
              href={`tel:${PhoneNumbers.EZDISPATCH_DIRECT}`}
            >
              {PhoneNumbers.EZDISPATCH_DIRECT}
            </EzLink>
            &nbsp;
            {t('help.urgent2')}
          </p>
          {orderNumber && (
            <InfoTextWrapper>
              {orderText}&nbsp;
              <EzTextStyle use="strong">{formatOrderNumber(orderNumber)}</EzTextStyle>.
            </InfoTextWrapper>
          )}
          <div>
            {t('help.otherQuestions')} &nbsp;
            <EzLink
              onClick={() =>
                trackButtonClick(DRIVER_HELP_EMAIL_LINK, {
                  driver_id: currentAccount?.id || null,
                  misc_json: JSON.stringify({
                    order_number: orderNumber || null,
                  }),
                })
              }
              href="mailto: ezdispatch@ezcater.com"
            >
              ezdispatch@ezcater.com
            </EzLink>
          </div>
        </EzCard>
        <EzHeading size={'1'}>{t('help.video.header')}</EzHeading>
        <EzCard>
          <div>
            {t('help.video.message')} &nbsp;
            <EzLink href={TrainingVideo.url} target={TrainingVideo.target}>
              {t('help.video.link')}
            </EzLink>
          </div>
        </EzCard>
        <EzHeading size={'1'}>{t('help.privacy.header')}</EzHeading>
        <EzCard>
          <div>
            <EzLink href="/privacy_policy.html" target="_blank">
              {t('help.privacy.privacyPolicy')}
            </EzLink>
          </div>
          <div>
            <EzLink href="/ca_notice_of_collection.html" target="_blank">
              {t('help.privacy.caNoticeOfCollection')}
            </EzLink>
          </div>
        </EzCard>

        {currentAccount?.id && (
          <>
            <EzHeading size={'1'}>{t('help.accountDeletion.header')}</EzHeading>
            <EzCard>
              <div>
                {t('help.accountDeletion.info')}{' '}
                <EzButton onClick={() => setAccountDeletionModalOpen(true)} use="tertiary">
                  {t('help.accountDeletion.link')}
                </EzButton>
              </div>
            </EzCard>
          </>
        )}

        {accountDeletionModalOpen && <AccountDeletionModal dismissModal={handleModalDismiss} />}
      </EzPage>
    </Layout>
  );
};

export default Help;
