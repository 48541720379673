import {VFC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EzCard, EzCardExpandable, EzHeading, EzCardSection} from '@ezcater/recipe';
import {parseISO, format} from 'date-fns';
import type {DriverAssignmentQuery} from 'services/graphql/types';
import CardHeader from './CardHeader';
import MapLink from './MapLink';
import ContactSection from '../InProgress/ContactSection';

type DmDelivery = DriverAssignmentQuery['driverAssignment']['delivery'];

type AddressProps =
  | {
      type: 'pickup';
      address: DmDelivery['pickupAddress'] | null;
      distanceInMiles?: never;
      durationInMinutes?: never;
      eventAt?: never;
    }
  | {
      type: 'dropoff';
      address: DmDelivery['dropoffAddress'] | null;
      eventAt: string;
      distanceInMiles: DmDelivery['distanceInMiles'];
      durationInMinutes: DmDelivery['durationInMinutes'];
    };

type Props = {
  isMapLinkDisabled?: boolean;
  driverAskFor?: string | null;
} & AddressProps;

const fontStyle = {fontWeight: 500};

const formatDate = (value: string) => format(parseISO(value), 'EEEE LLL d, p');

const DeliveryAddressCard: VFC<Props> = ({
  type,
  address,
  isMapLinkDisabled = true,
  driverAskFor,
  eventAt,
  distanceInMiles,
  durationInMinutes,
}) => {
  const {t} = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const toggle = () => setIsExpanded(expanded => !expanded);

  const labelType = type === 'pickup' ? 'Pickup' : 'Dropoff';

  const expandable = address?.instructions
    ? {
        isExpanded,
        onClick: toggle,
        expandLabel: t(`driverProgress.deliveryAddressCard.expandable.expand${labelType}Label`),
        collapseLabel: t(`driverProgress.deliveryAddressCard.expandable.collapse${labelType}Label`),
      }
    : undefined;

  return (
    <EzCard expandable={expandable}>
      <EzCardSection>
        <div>
          <CardHeader
            type={type}
            distanceInMiles={distanceInMiles}
            durationInMinutes={durationInMinutes}
          />
          {typeof eventAt === 'string' && (
            <EzHeading style={fontStyle} size="5">
              {formatDate(eventAt)}
            </EzHeading>
          )}
          <EzHeading color="blue" size="2">
            {address?.locationName ?? ''}
          </EzHeading>
          <MapLink disabled={isMapLinkDisabled} address={address} />
        </div>
        {expandable && (
          <EzCardExpandable isExpanded={isExpanded}>
            <div style={{marginTop: '1rem'}} />
            <ContactSection
              type={type}
              instructions={address?.instructions}
              driverAskFor={driverAskFor}
            />
          </EzCardExpandable>
        )}
      </EzCardSection>
    </EzCard>
  );
};

export default DeliveryAddressCard;
