import {ReactNode} from 'react';
import CurrentAssignmentContext from './CurrentAssignmentContext';

type CurrentAssignmentProviderProps = {
  id: string | null | undefined;
  deliveryId: string | null | undefined;
  driverId: string | null | undefined;
  orderNumber: string | null | undefined;
  children: ReactNode;
};

const CurrentAssignmentProvider = ({
  id,
  deliveryId,
  driverId,
  orderNumber,
  children,
}: CurrentAssignmentProviderProps) => (
  <CurrentAssignmentContext.Provider
    value={{
      deliveryId,
      id,
      driverId,
      orderNumber,
    }}
  >
    {children}
  </CurrentAssignmentContext.Provider>
);

export default CurrentAssignmentProvider;
