import {EzLayout} from '@ezcater/recipe';
import styled from '@emotion/styled';
import {VFC} from 'react';
import {useTranslation} from 'react-i18next';
import {useCurrentAccount} from 'components/CurrentAccount';
import CallCustomerButton from './CallCustomerButton';
import CallEzCaterButton from './CallEzCaterButton';
import {ButtonGroupWrapper} from './CallCard.styles';

interface Props {
  phone?: string | null;
  driverAssignmentId: string;
}

const Callout = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

const CallCard: VFC<Props> = ({phone, driverAssignmentId}) => {
  const {t} = useTranslation();
  const {id: driverId} = useCurrentAccount();

  return (
    <EzLayout layout="stack">
      <Callout>{t('driverProgress.callCard.questionsOrIssues')}</Callout>
      <ButtonGroupWrapper>
        <EzLayout layout="equal">
          <CallCustomerButton
            driverId={driverId}
            driverAssignmentId={driverAssignmentId}
            phone={phone}
          />
          <CallEzCaterButton driverId={driverId} driverAssignmentId={driverAssignmentId} />
        </EzLayout>
      </ButtonGroupWrapper>
    </EzLayout>
  );
};

export default CallCard;
