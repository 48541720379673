import gql from 'graphql-tag';

// The operation name of this mutation MUST be MagicLinkRequest
// so that the operation name allowlist works properly in dm-rails
const RequestMagicLink = gql(/* GraphQL */ `
  mutation MagicLinkRequest($input: MagicLinkRequestInput!) {
    magicLinkRequest(input: $input) {
      success
      errors {
        code
        message
      }
    }
  }
`);

export default RequestMagicLink;
