import {themes} from '@ezcater/recipe';
import {
  baseFontFamily,
  borderRadius,
  borderWidth,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
} from '@recipe-ui/legacy-theme';

const {createTheme, ezFulfillmentTheme} = themes;

export const baseTheme = {
  baseFontFamily,
  borderRadius,
  borderWidth,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  spacing: [
    'calc(4rem / 14)',
    'calc(8rem / 14)',
    'calc(12rem / 14)',
    'calc(16rem / 14)',
    'calc(20rem / 14)',
    'calc(24rem / 14)',
    'calc(32rem / 14)',
    'calc(48rem / 14)',
    'calc(60rem / 14)',
    'calc(84rem / 14)',
  ],
  recipeSpacing: {
    xs2: 'calc(4rem / 14)',
    xs: 'calc(8rem / 14)',
    sm: 'calc(12rem / 14)',
    md: 'calc(16rem / 14)',
    lg: 'calc(20rem / 14)',
    xl: 'calc(24rem / 14)',
    xl2: 'calc(32rem / 14)',
    xl3: 'calc(48rem / 14)',
    xl4: 'calc(60rem / 14)',
    xl5: 'calc(84rem / 14)',
  },
} as const;

export default {
  ...baseTheme,
  ...createTheme(ezFulfillmentTheme, baseTheme),
} as const;
