import {ComponentProps, MouseEventHandler, VFC} from 'react';
import {EzButton} from '@ezcater/recipe';
import {useFlashMessages} from '@ezcater/flash-messages';
import {useMutation} from 'services/apollo';
import {DmDeliveryEventTypes} from 'services/graphql/types';
import type {
  CreateDeliveryEventMutation,
  CreateDeliveryEventMutationVariables,
} from 'services/graphql/types';
import {CreateDeliveryEventQuery} from 'services/graphql';
import styled from '@emotion/styled';
import {useTranslation} from 'react-i18next';
import {trackButtonClick} from 'util/tracking';
import StartDeliveryButton from './StartDeliveryButton';
import CompleteDeliveryButton from './CompleteDeliveryButton';

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  margin-top: 1rem;
  padding: 1rem 1.5rem 1rem 1.5rem;

  & > * {
    height: 100%;
    width: 100%;
    padding: 0.5rem 0 0.5rem 0;
  }
`;

interface Props {
  readonly eventAt: string;
  readonly driverAssignmentId: string;
  readonly status: `${DmDeliveryEventTypes}`;
}

// Map to relate the status to the
const STATUS_MAP = {
  [DmDeliveryEventTypes.DriverAssigned]: DmDeliveryEventTypes.DriverPickedUp,
  [DmDeliveryEventTypes.DriverPickedUp]: DmDeliveryEventTypes.DriverDroppedOff,
} as const;

function isValidStatus(x: string): x is keyof typeof STATUS_MAP {
  return x in STATUS_MAP;
}

type PrimaryButtonProps = Omit<ComponentProps<typeof EzButton>, 'use'>;

type ButtonProps = Pick<Props, 'driverAssignmentId'> &
  PrimaryButtonProps & {
    /**
     * The status to transition to next.
     */
    readonly nextStatus: (typeof STATUS_MAP)[keyof typeof STATUS_MAP];
    /**
     * The current status
     */
    readonly status: keyof typeof STATUS_MAP;
    readonly eventAt: string;
  };

const UpdateDeliveryButton: VFC<ButtonProps> = ({
  driverAssignmentId,
  status,
  nextStatus,
  eventAt,
}) => {
  const {t} = useTranslation();
  const errorLabel = t('driverProgress.actionButtons.error');
  const uncaughtErrorMessage = t('driverProgress.actionButtons.uncaughtError');
  const {addFlashMessage} = useFlashMessages();
  const [updateDelivery, {loading}] = useMutation<
    CreateDeliveryEventMutation,
    CreateDeliveryEventMutationVariables
  >(CreateDeliveryEventQuery, {
    variables: {
      input: {
        driverAssignmentId,
        eventType: nextStatus,
      },
    },
    onCompleted(result) {
      result?.createDeliveryEvent?.errors?.forEach(error => {
        addFlashMessage({
          type: 'error',
          headline: errorLabel,
          message: error,
        });
      });
    },
    onError() {
      addFlashMessage({
        type: 'error',
        headline: errorLabel,
        message: uncaughtErrorMessage,
      });
    },
  });

  const handleClick: MouseEventHandler<HTMLButtonElement> = async () => {
    await updateDelivery();

    trackButtonClick(`${nextStatus}-button`.replace(/_/g, '-'), {
      misc_json: JSON.stringify({
        driverAssignmentId,
      }),
    });
  };

  if (status === DmDeliveryEventTypes.DriverAssigned) {
    return <StartDeliveryButton loading={loading} eventAt={eventAt} onClick={handleClick} />;
  }

  if (status === DmDeliveryEventTypes.DriverPickedUp) {
    return <CompleteDeliveryButton loading={loading} onClick={handleClick} />;
  }

  return null;
};

/**
 * Provides the appropriate action given the current state of some order
 * If no action is available for a given state, returns null
 *
 * @param param0
 * @returns
 */
const ActionButton: VFC<Props> = ({driverAssignmentId, status, eventAt}) => {
  if (!isValidStatus(status)) {
    return null;
  }

  const nextStatus = STATUS_MAP[status];

  return (
    <Container>
      <UpdateDeliveryButton
        status={status}
        nextStatus={nextStatus}
        eventAt={eventAt}
        driverAssignmentId={driverAssignmentId}
      />
    </Container>
  );
};

export default ActionButton;
