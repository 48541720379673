import styled from '@emotion/styled';

interface ButtonWrapperProps {
  smallText: boolean | undefined;
  fullWidth?: boolean;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: center;

  button {
    font-size: ${({smallText, theme}) => (smallText ? theme.fontSizes[300] : 'inherit')};
  }

  & * {
    ${props => props.fullWidth && 'width: 100%;'}
  }

  ${({theme}) => theme.breakpoints.down('md')} {
    button {
      font-size: ${({theme}) => theme.fontSizes[300]};
      width: 100%;
      padding: ${({theme}) => theme.recipeSpacing.sm};
    }
  }
`;
