import gql from 'graphql-tag';

const DriverAcceptSmsTerms = gql(/* GraphQL */ `
  mutation DriverAcceptSmsTerms($input: DriverAcceptSmsTermsInput!) {
    driverAcceptSmsTerms(input: $input) {
      errors
      driver {
        id
      }
    }
  }
`);

export default DriverAcceptSmsTerms;
