import gql from 'graphql-tag';

const SelfAssignDriver = gql(/* GraphQL */ `
  mutation SelfAssignDriver($input: SelfAssignDriverInput!) {
    selfAssignDriver(input: $input) {
      errors
      driverAssignmentId
    }
  }
`);

export default SelfAssignDriver;
