import {Helmet as ReactHelmet} from 'react-helmet';

interface HelmetProps {
  children: any;
  title: string;
}

const Helmet = ({children, title}: HelmetProps) => (
  <>
    <ReactHelmet>
      <title>{title}</title>
    </ReactHelmet>
    {children}
  </>
);

export default Helmet;
