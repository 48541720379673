import gql from 'graphql-tag';

const MaskedPhoneNumber = gql(/* GraphQL */ `
  mutation MaskedPhoneNumber($input: MaskedPhoneNumberInput!) {
    maskedPhoneNumber(input: $input) {
      errors
      driverAssignment {
        id
        deliveryStatus
        maskedPhoneNumber
      }
    }
  }
`);

export default MaskedPhoneNumber;
