import {MouseEventHandler} from 'react';
import {useMutation} from 'services/apollo';
import {useFlashMessages} from '@ezcater/flash-messages';
import {useTranslation} from 'react-i18next';
import {EzButton} from '@ezcater/recipe';
import {MaskedPhoneNumber as MaskedPhoneNumberQuery} from 'services/graphql';
import type {
  MaskedPhoneNumberMutation,
  MaskedPhoneNumberMutationVariables,
} from 'services/graphql/types';
import {CALL_CUSTOMER_BUTTON} from 'util/tracking/trackingEvents';
import {trackButtonClick} from 'util/tracking/trackEvent';

const callPhoneNumber = (phoneNumber: string) => {
  window.location.assign(`tel:${phoneNumber}`);
};

interface CallCustomerButtonProps {
  readonly phone?: string | null;
  readonly driverAssignmentId: string;
  readonly driverId: string | null;
}

const CallCustomerButton = ({phone, driverAssignmentId, driverId}: CallCustomerButtonProps) => {
  const {t} = useTranslation();
  const {addFlashMessage} = useFlashMessages();

  const [getMaskedPhoneNumber, {loading}] = useMutation<
    MaskedPhoneNumberMutation,
    MaskedPhoneNumberMutationVariables
  >(MaskedPhoneNumberQuery);

  const handleGetMaskedPhoneNumber = async () => {
    try {
      const response = await getMaskedPhoneNumber({
        variables: {
          input: {
            driverAssignmentId,
          },
        },
      });

      const errorCount = response?.data?.maskedPhoneNumber?.errors?.length ?? 0;
      if (response?.data?.maskedPhoneNumber?.driverAssignment?.maskedPhoneNumber) {
        callPhoneNumber(response.data.maskedPhoneNumber.driverAssignment.maskedPhoneNumber);
      }

      if (errorCount > 0) {
        addFlashMessage({
          type: 'error',
          headline: t('driverProgress.callCard.error.headline'),
          message: t('driverProgress.callCard.error.message'),
        });
      }
    } catch (error) {
      addFlashMessage({
        type: 'error',
        headline: t('driverProgress.callCard.error.headline'),
        message: t('driverProgress.callCard.error.message'),
      });
    } finally {
      trackButtonClick(CALL_CUSTOMER_BUTTON, {
        driver_id: driverId,
        misc_json: JSON.stringify({
          driverAssignmentId,
        }),
      });
    }
  };

  const onClick: MouseEventHandler<HTMLButtonElement> = () => handleGetMaskedPhoneNumber();
  const title = phone ? undefined : 'Phone number not provided';
  const disabledMessage =
    phone && !loading ? undefined : t('driverProgress.callCard.callCustomerDisabledReason');

  return (
    <EzButton
      title={title}
      disabled={!phone || loading}
      disabledMessage={disabledMessage}
      onClick={onClick}
      loading={loading}
      use="secondary"
      style={{padding: '1rem'}}
    >
      {t('driverProgress.callCard.callCustomer')}
    </EzButton>
  );
};

export default CallCustomerButton;
