import './config/initializeSentry';
import ReactDOM from 'react-dom';
import {CacheProvider} from '@emotion/core';
import initializeDatadogRum from 'services/initializeDatadogRum';
import createCache from '@emotion/cache';
import App from './App';

initializeDatadogRum();

const cache = createCache();
cache.compat = true;

ReactDOM.hydrate(
  <CacheProvider value={cache}>
    <App />
  </CacheProvider>,
  document.getElementById('root')
);
