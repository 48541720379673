import {useEffect} from 'react';
import {trackPageView} from './trackEvent';

export const useDriverPageViewTracking = (
  pageName: string,
  delivery_id: string | undefined,
  driver_id: string | undefined
): void => {
  useEffect(() => {
    if (driver_id && delivery_id) {
      trackPageView(pageName, {driver_id, delivery_id});
    }
  }, [driver_id, delivery_id]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const usePageViewTracking = (pageName: string): void =>
  useEffect(() => {
    trackPageView(pageName, {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
