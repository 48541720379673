import {ReactNode} from 'react';
import type {ApolloQueryResult} from 'apollo-client';
import {PROGRESS_PATH, REQUEST_SIGN_IN_LINK_PATH, SELF_ASSIGN_PATH} from 'paths';
import {Redirect, useHistory, useRouteMatch, RouteComponentProps} from 'react-router-dom';
import queryString from 'query-string';
import type {MeQuery, MeQueryVariables} from 'services/graphql/types';
import {SelfAssignSource} from 'services/graphql/types';
import MagicLinkSessionRedirect from './MagicLinkSessionRedirect';

const getMagicLinkUrlParams = (
  location: RouteComponentProps['location']
): {magicLinkToken?: string; source?: `${SelfAssignSource}`} => {
  const parsedQueryString = queryString.parse(location.search);

  return {
    magicLinkToken: parsedQueryString.t as string | undefined,
    source: parsedQueryString.source as SelfAssignSource | undefined,
  };
};

interface DriverAuthEnabledRedirectProps {
  id: string;
  firstName: string;
  lastName: string;
  children: ReactNode;
  redirect: boolean;
  refetch: (variables?: MeQueryVariables) => Promise<ApolloQueryResult<MeQuery>>;
}

const DriverAuthEnabledRedirect = ({
  id,
  firstName,
  lastName,
  refetch,
  redirect,
  children,
}: DriverAuthEnabledRedirectProps) => {
  const history = useHistory();

  const {magicLinkToken, source} = getMagicLinkUrlParams(history.location);
  const driverAssignmentMatch = useRouteMatch<{driverAssignmentId: string}>(PROGRESS_PATH);
  const driverAssignmentId = driverAssignmentMatch?.params?.driverAssignmentId;
  const selfAssignDeliveryMatch = useRouteMatch<{deliveryId: string}>(SELF_ASSIGN_PATH);
  const deliveryId = selfAssignDeliveryMatch?.params?.deliveryId;

  const userNotLoggedIn = id === '';
  const isQrCodeSelfAssign = source === SelfAssignSource.Qrcode && !!deliveryId;
  const isSmsSelfAssign = source === SelfAssignSource.Sms && !!deliveryId;
  const isSelfAssign = isQrCodeSelfAssign || isSmsSelfAssign;
  const selfAssignRequiringAuth = isSelfAssign && userNotLoggedIn;

  const shouldRedirect = redirect && userNotLoggedIn;

  // Due to the way QR codes are generated (in ez-rails), we can't send drivers a link
  // that contains a magic link token. We account for that here by directing them to the
  // page to request a magic link that does contain a token
  const shouldRedirectToRequestMagicLink =
    (shouldRedirect || selfAssignRequiringAuth) && !magicLinkToken;

  const shouldCreateDriverSession = (shouldRedirect || selfAssignRequiringAuth) && !!magicLinkToken;

  const shouldRequestName = !!id && (!firstName || !lastName) && isSelfAssign;

  if (shouldCreateDriverSession) {
    return (
      <MagicLinkSessionRedirect
        token={magicLinkToken}
        refetch={refetch}
        driverAssignmentId={driverAssignmentId}
        deliveryId={deliveryId}
        source={source}
      />
    );
  }

  if (shouldRedirectToRequestMagicLink) {
    const redirectParams = isSelfAssign ? `?deliveryId=${deliveryId}&source=${source}` : '';

    return <Redirect push to={`${REQUEST_SIGN_IN_LINK_PATH}${redirectParams}`} />;
  }

  if (shouldRequestName) {
    const sourceRedirectValue = source ? `?source=${source}` : '';

    return <Redirect push to={`/self_assign_registration/${deliveryId}${sourceRedirectValue}`} />;
  }

  return <>{children}</>;
};

export default DriverAuthEnabledRedirect;
